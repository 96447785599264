import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import { ReduxAction } from "../../types/global";
import { reasons } from "../../utils/redirectionReason";
import redirectActionService from "../actions/redirections";

import { certificatePropsApi } from "./query/certificateProps";
import type { TRedirectionStore, TRedirectState } from "./types/redirection.types";

const initialState: TRedirectionStore = {
    redirect: null,
};

const redirectionSlice = createSlice({
    name: "redirection",
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(
                redirectActionService.doRedirect.fulfilled,
                (_state: TRedirectionStore, action: ReduxAction<TRedirectState>) => ({
                    redirect: action.payload,
                })
            )
            // Matcher for query rejects
            .addMatcher(
                isAnyOf(
                    certificatePropsApi.endpoints.getCertificateDetails.matchRejected,
                    certificatePropsApi.endpoints.getCertificateProps.matchRejected
                ),
                (state, action: ReduxAction<FetchBaseQueryError | undefined>) => {
                    if (action.error.message === "Rejected" && action.payload) {
                        console.warn(
                            "Redirect because an error. ",
                            action.meta.arg.endpointName,
                            action.payload.status
                        );
                        if (
                            action.meta.arg.endpointName === "getCertificateDetails" &&
                            !Number.isNaN(Number(action.payload.status)) &&
                            (action.payload.status as number) >= 400 &&
                            (action.payload.status as number) < 500
                        ) {
                            return { redirect: reasons().slug_not_found.redirect };
                        }
                        if (
                            action.meta.arg.endpointName === "getCertificateProps" &&
                            !Number.isNaN(Number(action.payload.status)) &&
                            (action.payload.status as number) >= 400 &&
                            (action.payload.status as number) < 500
                        ) {
                            return { redirect: reasons().slug_not_found.redirect };
                        }
                    }
                    // If the reject error is not spezified as an api error, ignore it.
                    return state;
                }
            ),
});

export default redirectionSlice.reducer;
