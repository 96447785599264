import { Link } from "react-router-dom";

import useTranslation from "i18n/hooks/useTranslation";

import { InputAdornment, Stack } from "@mui/material";

import Button from "components/shared/Button";
import TextField from "components/shared/TextField/TextField";
import Typography from "components/shared/Typography";

import { useValidateToken } from "../../../../redux/hooks/validation";

import * as styles from "./TokenValidationInput.styles";

const TokenValidationInput = () => {
    const t = useTranslation();

    const { inputToken, setInputToken, verifyToken, isVerificationAllowed } = useValidateToken();

    return (
        <div>
            <Typography variant="h5">{t("certificate.authForm.form.description")}</Typography>
            <Stack direction="row">
                <TextField
                    fullWidth
                    name="token-validation-input"
                    value={inputToken}
                    onChange={(e) => setInputToken(e.target.value.replace(" ", ""))}
                    placeholder={t("certificate.authForm.form.input.placeholder")}
                    sx={styles.tokenInput}
                    // Extend the maxLength by 4 in case a recipient enters spaces in front of the token. uuid is 36 long.
                    inputProps={{ maxLength: 36 + 4 }}
                    // We need both inputProps & InputProps. The linter identifies it as the same prop.
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={styles.inputContainer}>
                                <Button
                                    id="verify-auth-token-button"
                                    variant="contained"
                                    onClick={() => verifyToken({ useInput: true, removeAllAccessToken: true })}
                                    disabled={!isVerificationAllowed}
                                    sx={styles.verifyButton}
                                >
                                    {t("certificate.authForm.form.email_verification.verify_button.verify")}
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") verifyToken({ useInput: true, removeAllAccessToken: true });
                    }}
                />
            </Stack>
            <Typography variant="body1" color="text.secondary" sx={{ pt: 2 }}>
                {t("certificate.authForm.form.email_verification.enter_code_helper")}{" "}
                <Link to="https://help.virtualbadge.io/article/access-token" target="_blank">
                    {t("certificate.authForm.form.email_verification.enter_code_helper_link")}
                </Link>
            </Typography>
        </div>
    );
};

export default TokenValidationInput;
