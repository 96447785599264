/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { store } from "features/certificateDownload/components/PolotnoWorkspace/PolotnoWorkspace";
import { IConfigPdfCreation, IParticipantPdfCreation } from "features/certificateDownload/types/utils";
import {
    handleMultiPagePdfCreation,
    handleMultiPagePdfCreationWithReturn,
} from "features/certificateDownload/utils/pdfGeneration/pdf.helpers";
import { downloadImagesAsZip } from "features/certificateDownload/utils/Polotno/exportPages";
import {
    checkBadgeFormat,
    getBadgeFormatSize,
    getIdNumberAndValidationUrlMeasurements,
} from "features/certificateDownload/utils/Polotno/Polotno.helpers";
import {
    generateAndDownloadPdf,
    generateAndReturnPdfBlob,
    generateValidationPage,
} from "features/certificateDownload/utils/Polotno/validationPage";
import { saveAs } from "file-saver";
import i18next from "i18next";
import JSZip from "jszip";
import { cloneDeep } from "lodash";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";

import useInvalidateToken from "hooks/useInvalidateToken";

import DownloadProgress from "../../features/customProvider/NotificationProvider/CustomSnackbars/DownloadProgress";
import useTranslation from "../../i18n/hooks/useTranslation";
import certificatesActionService from "../../redux/actions/certificates";
import sideActionsService from "../../redux/actions/sideActions";
import { useGetCurrentSelectedCertificate } from "../../redux/hooks/badge";
import { useGetCertificateDetails } from "../../redux/hooks/queryHooks";
import { useGetOrgaSlugs } from "../../redux/hooks/validation";
import certificatesSelectorService from "../../redux/selector/certificates";
import controlSelectorService from "../../redux/selector/control";
import eventSelectorService from "../../redux/selector/events";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { EVariantTranslation } from "../../types/enums";
import backendPdfDownloadHelper from "../../utils/backendPdfDownloadHelper";
import { findRecipientInformation } from "../../utils/findRecipientInformation";
import { downloadFile, getTranslatedCertificateOrBadge } from "../../utils/misc";
import sanitizeFileName from "../../utils/sanitizeFileName";

import { setGeneratedPagesToPolotnoProps } from "./certificateDownload.helpers";

/**
 * Generate the second page and get the text positions for the id number and validation url if the second page is enabled.
 *
 * @param {boolean} hasSecondPage - A boolean to determine if the second page is enabled.
 * @param {Object} participant - An object containing the participant information.
 * @param {Object} config - An object containing the configuration for the PDF creation.
 * @returns {Object} An object containing the second page and the text positions.
 */
const getSecondPageAndTextPositions = async (
    hasSecondPage: boolean,
    participant: IParticipantPdfCreation,
    config: IConfigPdfCreation
) => {
    if (!hasSecondPage) return { addedPage: undefined, textPositions: undefined };

    const infoPage = store.addPage({ ...(await generateValidationPage(participant, config)) });

    // Get the measures for the second page (id number and validation url)
    const textPositions = getIdNumberAndValidationUrlMeasurements(store, participant.validationPageEnabled);

    return { infoPage, textPositions };
};

/**
 * This hook contains the code for the certificate download page.
 * It is responsible for generating and downloading the certificates.
 */
export const useCertificateDownload = () => {
    const t = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { closeSnackbar } = useSnackbar();

    const { resetToken } = useInvalidateToken();

    const certificates = useAppSelector(certificatesSelectorService.selectCertificates);
    const currentCertificate = useGetCurrentSelectedCertificate();

    const slugs = useGetOrgaSlugs();
    const token = useAppSelector(controlSelectorService.selectToken);
    const certificate = useAppSelector(eventSelectorService.selectFetchedEvent);
    const { data: certificateDetails } = useGetCertificateDetails();

    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);
    const userFlow = useAppSelector(controlSelectorService.selectUserFlow);
    const tokenValidationResponse = useAppSelector(controlSelectorService.selectTokenValidation);

    const [loading, setLoading] = useState(false);

    const selectedCertificatePreview = useMemo(() => {
        if (userFlow.showPreview) return eventDetails.event_badge_preview_url;
        return (
            currentCertificate.badge_image_url ||
            currentCertificate.badge_base_string ||
            eventDetails.event_badge_preview_url
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCertificate, eventDetails.event_badge_preview_url, userFlow.showPreview, userFlow.showedCertificate]);

    const selectedCertificateDetails: Data | null = useMemo(() => {
        if (!certificate) {
            setLoading(true);
            return null;
        }
        return currentCertificate || certificates.data[0];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [certificate, currentCertificate, userFlow.showedCertificate]);

    const getCertificateImageToBlob = async (recipient: Data): Promise<Blob | null> => {
        const usedBadgeString = recipient.badge_image_url || recipient.badge_base_string;
        if (!usedBadgeString) return null;
        const response = await fetch(usedBadgeString);
        const blob = await response.blob();
        return blob;
    };

    const createPolotnoPdf = async (
        config: IConfigPdfCreation,
        participant: IParticipantPdfCreation,
        font: Uint8Array
    ) => {
        dispatch(sideActionsService.setBadgeParseLoadingState(true));
        const { hasSecondPage, badgeFormat, certificateBlobImageUrl, certificatePages } = config;

        const polotnoPropsToLoad = setGeneratedPagesToPolotnoProps(
            participant.design_props,
            certificateBlobImageUrl,
            certificatePages
        );

        store.loadJSON(polotnoPropsToLoad);
        await store.waitLoading();
        // Set page size to default if it is still reduced. Old certificates may have smaller resolution
        // and we have to convert it to the normal before processing.
        const isFormatReduced = checkBadgeFormat(polotnoPropsToLoad) === "reduced";
        if (isFormatReduced) {
            const { width, height } = getBadgeFormatSize(badgeFormat);
            // 'true' here means 'all elements will be replaced/resized automatically under the hood'
            store.setSize(width, height, true);
        }

        const { infoPage, textPositions } = await getSecondPageAndTextPositions(hasSecondPage, participant, config);

        // Generate the PDF
        const cert = await store.toPDFDataURL({
            pixelRatio: 8,
        });

        if (infoPage) store.deletePages([infoPage.id]); // Remove added page, so it is not added again when clicking download
        const base64Data = cert.split(",")[1];
        const { pdfBlob, fileName } = await generateAndReturnPdfBlob(
            base64Data,
            font,
            participant,
            config,
            textPositions
        );
        dispatch(sideActionsService.setBadgeParseLoadingState(false));
        store.clear();
        return { pdfBlob, fileName };
    };

    const buildCertificatePdf = async (
        config: IConfigPdfCreation,
        participant: IParticipantPdfCreation,
        badge_type: "badge" | "polotno",
        font: Uint8Array
    ) => {
        const clonedConfig = cloneDeep(config);
        const clonedParticipant = cloneDeep(participant);

        let badgePdf: {
            pdfBlob?: any;
            fileName?: string;
        } = {};

        let polotnoPdf: {
            pdfBlob?: any;
            fileName?: string;
        } = {};

        switch (badge_type) {
            case "badge":
                badgePdf = await handleMultiPagePdfCreationWithReturn(clonedConfig, clonedParticipant, font);
                return badgePdf;
            case "polotno":
                polotnoPdf = await createPolotnoPdf(clonedConfig, clonedParticipant, font);
                return polotnoPdf;
            default:
                break;
        }

        throw Error(
            `There is a problem with the export of your certificate ${participant.certificate_id}. Please contact our support!`
        );
    };

    const setDownloadLoadingState = (state: boolean) =>
        dispatch(sideActionsService.setLoadingState({ type: "download", state }));

    const handleSingleDownloadCertificate = async (type: "pdf" | "png", recipient: Data) => {
        const checkSingleRecipient = backendPdfDownloadHelper.checkSingleRecipient(
            eventDetails.event_pdf_type,
            recipient
        );

        const isBackendPdfWithoutUrl = checkSingleRecipient.isBackendPdf && !checkSingleRecipient.isGenerated;

        dispatch(certificatesActionService.setDownloadFormat(type));
        const downloadLink = document.createElement("a");

        try {
            setDownloadLoadingState(true);
            const recipientName = findRecipientInformation(recipient.field_mapping, "username");
            const recipientImgUrl = await getCertificateImageToBlob(recipient);
            if (!recipientImgUrl) return;
            const config: IConfigPdfCreation = {
                pdfLanguage: i18next.language as "en" | "de",
                customCertificateValidationUrl: recipient.validation_url,
                eventCertificateIssuer: certificateDetails?.event__certificate_issuer ?? "",
                eventName: eventDetails?.event_name ?? "",
                aboutCertificate: certificateDetails?.event__certificate_description ?? "",
                badgeFormat: eventDetails?.badge_format || "A4_PORTRAIT",
                certificatePageLink: recipient.validation_url,
                certificateDetails,
                pdfProps: certificateDetails as ICertificateDetails,
                certificateBlobImageUrl: recipient.badge_image_url || undefined,
                certificatePages: recipient.certificate_pages || [],
                hasSecondPage: eventDetails?.event_pdf_second_page_enabled ?? false,
            };

            const participant: IParticipantPdfCreation = {
                design_props: recipient.badge_props,
                recipient_name: recipientName,
                issue_date: recipient.issue_date || eventDetails.issue_date || "",
                expiration_date: recipient.expiration_date || eventDetails.expiration_date || "",
                certificate_id: recipient.certificate_id ?? "",
                pdfBlobUrl: null,
                validationPageEnabled: recipient.validation_page_enabled,
            };

            let updateStats = true;

            switch (type) {
                case "pdf":
                    if (isBackendPdfWithoutUrl) {
                        enqueueSnackbar(
                            t("certificate_download.error.backend_pdf_without_url", {
                                certificateType: getTranslatedCertificateOrBadge(
                                    eventDetails?.event_type,
                                    EVariantTranslation.LOWERCASE_SINGULAR
                                ),
                            }),
                            {
                                variant: "warning",
                            }
                        );
                        break;
                    }
                    if (recipient.pdf_blob_url) {
                        downloadFile(
                            recipient.pdf_blob_url,
                            sanitizeFileName(participant.certificate_id.substring(0, 4), {
                                prefix: recipientName,
                                fileType: "pdf",
                            })
                        );
                        break;
                    }
                    if (recipient.badge_props_type === "badge") {
                        await handleMultiPagePdfCreation(config, participant);
                    }
                    if (recipient.badge_props_type === "polotno") {
                        dispatch(sideActionsService.setBadgeParseLoadingState(true));
                        const { certificateBlobImageUrl, badgeFormat, hasSecondPage, certificatePages } = config;
                        const loadOpenSansFont = async () => {
                            // Fetch the font file from the URL
                            const fontResponse = await fetch(
                                "https://vbstatic.blob.core.windows.net/static/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf"
                            );
                            const fontBytes = new Uint8Array(await fontResponse.arrayBuffer());
                            return fontBytes;
                        };

                        const font = await loadOpenSansFont();

                        const polotnoPropsToLoad = setGeneratedPagesToPolotnoProps(
                            participant.design_props,
                            certificateBlobImageUrl,
                            certificatePages
                        );

                        store.loadJSON(polotnoPropsToLoad);
                        await store.waitLoading();

                        // Set page size to default if it is still reduced. Old certificates may have smaller resolution
                        // and we have to convert it to the normal before processing.
                        const isFormatReduced = checkBadgeFormat(polotnoPropsToLoad) === "reduced";
                        if (isFormatReduced) {
                            const { width, height } = getBadgeFormatSize(badgeFormat);
                            // 'true' here means 'all elements will be replaced/resized automatically under the hood'
                            store.setSize(width, height, true);
                        }

                        const { infoPage, textPositions } = await getSecondPageAndTextPositions(
                            hasSecondPage,
                            participant,
                            config
                        );

                        // Generate the PDF
                        const cert = await store.toPDFDataURL({
                            pixelRatio: 8,
                        });

                        if (infoPage) store.deletePages([infoPage.id]); // Remove added page, so it is not added again when clicking download
                        const base64Data = cert.split(",")[1];
                        await generateAndDownloadPdf(
                            base64Data,
                            font,
                            participant,
                            config,
                            downloadLink,
                            textPositions
                        );
                        store.clear();
                        dispatch(sideActionsService.setBadgeParseLoadingState(false));
                    }
                    break;
                case "png":
                    if (recipient.certificate_pages?.length) {
                        downloadImagesAsZip(recipient.certificate_pages, {
                            zipFileName: sanitizeFileName(participant.certificate_id.substring(0, 4), {
                                prefix: recipientName,
                                fileType: "zip",
                            }),
                        });
                    } else {
                        downloadFile(
                            recipient.badge_image_url as string,
                            sanitizeFileName(participant.certificate_id.substring(0, 4), {
                                prefix: recipientName,
                                fileType: "png",
                            })
                        );
                    }
                    break;
                default:
                    updateStats = false;
                    break;
            }
            if (updateStats)
                dispatch(
                    certificatesActionService.postStats({
                        certificate_id: recipient.certificate_id,
                        stats_type: "stats_download_count",
                    })
                );
        } catch (e) {
            console.error(e);
        } finally {
            downloadLink.remove();
            dispatch(certificatesActionService.setDownloadFormat());
            setDownloadLoadingState(false);
        }
    };

    const handleBulkDownloadCertificate = async (type: "pdf" | "png", recipients: Data[]) => {
        const checkAllRecipients = backendPdfDownloadHelper.checkAllRecipients(eventDetails.event_pdf_type, recipients);

        const isBackendWithoutPdfUrls = checkAllRecipients.isBackend && checkAllRecipients.notGenerated;

        if (type === "pdf" && isBackendWithoutPdfUrls) {
            enqueueSnackbar(t("certificate.download.no_downloadable_pdf"), { variant: "warning" });
            return;
        }

        if (recipients.length === 1) {
            handleSingleDownloadCertificate(type, recipients[0]);
            return;
        }
        let progress = 0;
        // Generate an uuid as snackbar identifier key for close handling.
        const snackbarKey = uuidv4();
        dispatch(sideActionsService.setDownloadProgressTotal(recipients.length));
        dispatch(certificatesActionService.setDownloadFormat(type));
        enqueueSnackbar(
            <DownloadProgress
                message={t("certificate.download.download_progress_text", { extension: type.toUpperCase() })}
            />,
            {
                key: snackbarKey,
                variant: "info",
                autoHideDuration: null,
                preventDuplicate: true,
            }
        );

        const zipper = new JSZip();

        const loadOpenSansFont = async () => {
            // Fetch the font file from the URL
            const fontResponse = await fetch(
                "https://vbstatic.blob.core.windows.net/static/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf"
            );
            const fontBytes = new Uint8Array(await fontResponse.arrayBuffer());
            return fontBytes;
        };

        try {
            setDownloadLoadingState(true);

            const font = await loadOpenSansFont();
            for await (const recipient of recipients) {
                const checkSingleRecipient = backendPdfDownloadHelper.checkSingleRecipient(
                    eventDetails.event_pdf_type,
                    recipient
                );
                let fileName = "";
                const recipientName = findRecipientInformation(recipient.field_mapping, "username");
                const recipientImgUrl = type === "png" ? await getCertificateImageToBlob(recipient) : "";
                if (type === "png" && !recipientImgUrl) return;
                const config: IConfigPdfCreation = {
                    pdfLanguage: i18next.language as "en" | "de",
                    customCertificateValidationUrl: recipient.validation_url,
                    eventCertificateIssuer: certificateDetails?.event__certificate_issuer ?? "",
                    eventName: eventDetails?.event_name ?? "",
                    aboutCertificate: certificateDetails?.event__certificate_description ?? "",
                    badgeFormat: eventDetails?.badge_format || "A4_PORTRAIT",
                    certificatePageLink: recipient.validation_url,
                    certificateDetails,
                    pdfProps: certificateDetails as ICertificateDetails,
                    certificateBlobImageUrl: recipient.badge_image_url || undefined,
                    certificatePages: recipient.certificate_pages || [],
                    hasSecondPage: eventDetails?.event_pdf_second_page_enabled ?? false,
                };

                const participant: IParticipantPdfCreation = {
                    design_props: recipient.badge_props,
                    recipient_name: recipientName,
                    issue_date: recipient.issue_date || eventDetails.issue_date || "",
                    expiration_date: recipient.expiration_date || eventDetails.expiration_date || "",
                    certificate_id: recipient.certificate_id ?? "",
                    pdfBlobUrl: recipient.pdf_blob_url,
                    validationPageEnabled: recipient.validation_page_enabled,
                    certificatePages: recipient.certificate_pages,
                };

                let pdfReturn: {
                    pdfBlob?: any;
                    fileName?: string;
                } = {};

                let updateStats = true;

                switch (type) {
                    case "pdf":
                        if (checkSingleRecipient.isBackendPdf) {
                            if (recipient.pdf_blob_url) {
                                pdfReturn = {
                                    pdfBlob: await fetch(recipient.pdf_blob_url!, { cache: "no-store" }).then((r) =>
                                        r.blob()
                                    ),
                                    fileName: sanitizeFileName(recipient.certificate_id.substring(0, 4), {
                                        prefix: recipientName,
                                        fileType: "pdf",
                                    }),
                                };
                            }
                        } else {
                            pdfReturn = await buildCertificatePdf(
                                config,
                                participant,
                                recipient.badge_props_type || "polotno",
                                font
                            );
                        }
                        zipper.file(pdfReturn.fileName || "unnamed.pdf", pdfReturn.pdfBlob);
                        break;
                    case "png":
                        if (recipient.certificate_pages?.length) {
                            // Create a folder for the recipient inside the zip.
                            const recipientFolder = zipper.folder(
                                sanitizeFileName(recipientName ?? "", { fileType: "", prefix: "" })
                            );
                            try {
                                const pageDownloads = recipient.certificate_pages.map(async (url, index) => {
                                    const response = await fetch(url);
                                    if (!response.ok) {
                                        throw new Error(`Failed to fetch image from ${url}`);
                                    }
                                    const imageBlob = await response.blob();
                                    const pageName = sanitizeFileName(`image-${index + 1}`, { fileType: "png" });
                                    recipientFolder?.file(pageName, imageBlob);
                                });
                                await Promise.all(pageDownloads);
                            } catch (error) {
                                console.error("Error downloading and zipping images:", error);
                            }
                        } else {
                            fileName = sanitizeFileName(participant.certificate_id.substring(0, 4), {
                                prefix: recipientName,
                                fileType: "png",
                            });
                            if (recipientImgUrl) zipper.file(fileName, recipientImgUrl || "");
                        }
                        break;
                    default:
                        updateStats = false;
                        break;
                }
                if (updateStats)
                    dispatch(
                        certificatesActionService.postStats({
                            certificate_id: recipient.certificate_id,
                            stats_type: "stats_download_count",
                        })
                    );

                progress += 1;
                dispatch(sideActionsService.setDownloadProgress(progress));
            }

            await zipper
                .generateAsync({ type: "blob" })
                .then((blob) => saveAs(blob, `${eventDetails?.event_name}.zip`));
        } catch (e) {
            console.error(e);
        } finally {
            closeSnackbar(snackbarKey);
            dispatch(certificatesActionService.setDownloadFormat());
            setDownloadLoadingState(false);
            dispatch(sideActionsService.resetDownloadProgress());
        }
    };

    useEffect(() => {
        setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedCertificatePreview) {
            setLoading(false);
        }
    }, [selectedCertificatePreview]);

    useEffect(() => {
        if (
            (tokenValidationResponse as unknown as AxiosError<ITokenValidationResponse>)?.response?.data?.severity ===
            "error"
        ) {
            resetToken();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenValidationResponse]);

    useEffect(() => {
        if (!token) navigate(`/${slugs.orga_slug}/${slugs.certificate_slug}/`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return {
        handleSingleDownloadCertificate,
        handleBulkDownloadCertificate,
        loading,
        hasPolotnoProps: selectedCertificateDetails?.badge_props_type === "polotno" || false,
    };
};
