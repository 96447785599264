/**
 *Check if parsed param is type of T
 *
 * @example isTypeOf<string>("Hello World") // returns true
 * @example isTypeOf<string>(12345) // returns false
 * @template T Generic type to check with param
 * @param {unknown} check Param that need to check if it is type of generic T
 * @return {boolean}  {check is T} True if param is type of T. false otherwise
 */
export function isTypeOf<T>(check: unknown): check is T {
    if (check as T) return true;
    return false;
}
