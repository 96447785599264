import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box } from "@mui/material";

import IconButton from "components/shared/IconButton";
import Typography from "components/shared/Typography";

import useBackArrow from "./backArrow.hooks";
import * as styles from "./BackArrow.styles";

const BackArrow = () => {
    const navigate = useBackArrow();
    return (
        <Box sx={styles.BackArrowContainer} onClick={navigate} role="button">
            <IconButton sx={styles.iconStyle} id="back-arrow">
                <ArrowBackIosNewIcon />
            </IconButton>
            <Typography gutterBottom={false} sx={{ fontSize: "1.5rem" }}>
                Back
            </Typography>
        </Box>
    );
};

export default BackArrow;
