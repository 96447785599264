import { FC, ReactElement, useEffect, useMemo, useState } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import styled from "@emotion/styled";

import { Checkbox, FormControlLabel, FormGroup, Skeleton, styled as mui_styled } from "@mui/material";

import useTranslation from "../../../i18n/hooks/useTranslation";
import certificatesActionService from "../../../redux/actions/certificates";
import controlSelectorService from "../../../redux/selector/control";
import eventSelectorService from "../../../redux/selector/events";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { EEventPdfType } from "../../../types/enums";
import { getUserLocaleDate } from "../../../utils/dates.helpers";
import { findRecipientInformation } from "../../../utils/findRecipientInformation";
import { pictureResizeToMaxSize } from "../../../utils/pictureResizeToMaxSize";
import { LIGHTER_BLUE, MAIN_BLUE } from "../../../utils/Theme/theme";

type Props = {
    certificate: Data;
    onClick: () => void;
};

type ItemBadgeProps = Record<"isBaked" | "isExpired" | "backendPdfInProgress" | "isLoadingNew", boolean>;

const CertificateItemDiv = styled.div({
    margin: "11.5px",
    width: "268px",
});

const CertificateImageDiv = mui_styled("div")(() => ({
    borderRadius: "5px",
    width: "268px",
    height: "268px",
    backgroundColor: LIGHTER_BLUE,
    border: `0px solid ${LIGHTER_BLUE}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "wait",
    "&.clickable": {
        cursor: "pointer",
        ":hover": {
            outline: `1px solid ${MAIN_BLUE}`,
        },
    },
}));

const getBadgeVariant = (variant: "success" | "warning" | "error" | "info"): string => {
    switch (variant) {
        case "success":
            return "#66bb6a";
        case "warning":
            return "#ffa726";
        case "error":
            return "#f44336";
        case "info":
        default:
            return "#29B6F6";
    }
};

const Badge = styled.div<{ variant: "success" | "warning" | "error" | "info" }>(({ variant }) => ({
    borderRadius: "3px",
    backgroundColor: getBadgeVariant(variant),
    color: "white",
    position: "relative",
    left: "-4px",
    bottom: "30px",
    minWidth: "50px",
    maxWidth: "150px",
    height: "20px",
    fontSize: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "700",
}));

const StyledInformations = styled.p({
    margin: "0px",
    padding: "0px",
    paddingLeft: "10px",
});

const CheckBoxLabel = ({ name, issueDate }: { name: string; issueDate: string | undefined }): ReactElement => {
    const t = useTranslation();
    return (
        <>
            <StyledInformations>{name}</StyledInformations>
            {issueDate ? (
                <StyledInformations>
                    {t("certificate_overview.card_item.issue_date")}: {getUserLocaleDate(issueDate)}
                </StyledInformations>
            ) : (
                ""
            )}
        </>
    );
};

const StyledFormGroup = styled(FormGroup)({
    marginTop: "10px",
});

const ItemBadge: FC<ItemBadgeProps> = ({ isBaked, isExpired, backendPdfInProgress, isLoadingNew }) => {
    const t = useTranslation();
    if (isLoadingNew || (isBaked && !isExpired && !backendPdfInProgress)) return null;
    let itemProps: { variant: "success" | "warning" | "error" | "info"; text: string } = {
        variant: "error",
        text: "",
    };

    if (!isBaked)
        itemProps = {
            variant: "info",
            text: t("certificate_overview.card_item.new") as string,
        };

    if (isExpired)
        itemProps = {
            variant: "warning",
            text: t("certificate_overview.card_item.expired") as string,
        };

    if (backendPdfInProgress)
        itemProps = {
            variant: "warning",
            text: t("certificate_overview.card_item.be_pdf_in_progress") as string,
        };

    return <Badge variant={itemProps.variant}>{itemProps.text.toUpperCase()}</Badge>;
};

const CertificateOverviewItem: FC<Props> = ({ certificate, onClick }) => {
    const t = useTranslation();
    const dispatch = useAppDispatch();
    const event = useAppSelector(eventSelectorService.selectFetchedEvent);
    const { issueDate } = useAppSelector(eventSelectorService.selectEventDates);
    const downloadControl = useAppSelector(controlSelectorService.selectDownload);
    const { loadingState } = useAppSelector(controlSelectorService.selectNewCertificatesLoadingState);

    const [loadedImg, setLoadedImg] = useState<string | null | undefined>(undefined);

    const isSelected = useMemo(
        () => downloadControl.certificatesToDownload.includes(certificate.certificate_id),
        [certificate.certificate_id, downloadControl.certificatesToDownload]
    );

    const selectCertificate = (replacementType: "add" | "remove") =>
        dispatch(
            certificatesActionService.selectCertificateToDownload({
                certificate: certificate.certificate_id,
                replacementType,
            })
        );

    const paramIndication = (url: string) => (url.includes("?") ? "&" : "?");

    useEffect(() => {
        const changeBakedPreview = () => {
            const { badge_image_url } = certificate;
            const timestamp = Date.now();
            const parsedImage = `${badge_image_url || ""}${paramIndication(badge_image_url || "")}${timestamp}`;
            setLoadedImg(parsedImage);
        };
        changeBakedPreview();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [certificate.badge_image_url, certificate.badge_base_string]);

    const isExpired = certificate.expiration_date !== null ? new Date(certificate.expiration_date) < new Date() : false;

    const backendGenerationInProgress =
        event.event_pdf_type === EEventPdfType.BACKEND && certificate.badge_image_url === null;

    const isBaking = !loadedImg;

    const isLoadingNewCertificates = loadingState !== null;

    return (
        <CertificateItemDiv>
            <div>
                <CertificateImageDiv
                    className={!isLoadingNewCertificates ? "clickable" : ""}
                    title={
                        isLoadingNewCertificates
                            ? t("certificate_overview.card_item.certificate_is_still_baking")
                            : t("certificate_overview.card_item.title")
                    }
                    onClick={() => !isLoadingNewCertificates && !isBaking && onClick()}
                >
                    {isLoadingNewCertificates ? (
                        <Skeleton variant="rectangular" width={226} height={226} />
                    ) : (
                        loadedImg && (
                            <ProgressiveImage
                                src={
                                    backendGenerationInProgress
                                        ? event.event_badge_preview_thumbnail_url || ""
                                        : loadedImg
                                }
                                placeholder={event.event_badge_preview_thumbnail_url}
                            >
                                {(src, loading) => (
                                    <img
                                        className={`image${loading ? " loading" : " loaded"}`}
                                        src={src}
                                        alt={certificate.certificate_id}
                                        style={{
                                            maxWidth: "226px",
                                            maxHeight: "226px",
                                            margin: "auto",
                                            WebkitBoxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
                                            MozBoxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
                                            boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
                                            ...pictureResizeToMaxSize("226px", event.badge_format),
                                            ...(loading
                                                ? {
                                                      filter: "blur(4px)",
                                                      clipPath: "inset(0)",
                                                  }
                                                : {
                                                      filter: backendGenerationInProgress
                                                          ? "grayscale(1) contrast(0.2) brightness(1.9)" // makes the image lighter and grayscaled when be pdf is true and in progress
                                                          : "blur(0px)",
                                                      transition: "filter 0.5s linear",
                                                      transitionDelay: "500ms",
                                                  }),
                                        }}
                                    />
                                )}
                            </ProgressiveImage>
                        )
                    )}
                </CertificateImageDiv>
                <div style={{ height: "0px" }}>
                    <ItemBadge
                        isBaked={!isBaking}
                        isExpired={isExpired}
                        backendPdfInProgress={backendGenerationInProgress}
                        isLoadingNew={isLoadingNewCertificates}
                    />
                </div>
            </div>
            {isLoadingNewCertificates ? (
                <div
                    style={{
                        height: "48px",
                        width: "232px",
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        margin: "10px auto 0px",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                    }}
                >
                    <div style={{ height: "26px", width: "26px" }}>
                        <Skeleton variant="rectangular" width={26} height={26} />
                    </div>
                    <div
                        style={{
                            height: "48px",
                            width: "176px",
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            paddingLeft: "10px",
                        }}
                    >
                        <Skeleton variant="text" width={170} sx={{ fontSize: "1rem" }} />
                        <Skeleton variant="text" width={170} sx={{ fontSize: "1rem" }} />
                    </div>
                </div>
            ) : (
                <StyledFormGroup>
                    <FormControlLabel
                        disabled={isBaking || backendGenerationInProgress}
                        control={
                            <Checkbox
                                checked={isSelected}
                                onChange={(_event, checked) => selectCertificate(checked ? "add" : "remove")}
                            />
                        }
                        label={
                            <CheckBoxLabel
                                name={findRecipientInformation(certificate.field_mapping, "username") || ""}
                                issueDate={certificate.issue_date || issueDate}
                            />
                        }
                        labelPlacement="end"
                        sx={{
                            "&.MuiFormControlLabel-root": {
                                justifyContent: "center",
                                marginRight: 0,
                            },
                        }}
                    />
                </StyledFormGroup>
            )}
        </CertificateItemDiv>
    );
};

export default CertificateOverviewItem;
