import React from "react";

import BackArrow from "components/BackArrow/BackArrow";
import Footer from "components/Footer";

import * as styles from "./LegalContainer.styles";

const LegalContainer: React.FC<AppLayoutProps> = ({ children }) => {
    return (
        <div style={styles.legalContainer}>
            <BackArrow />
            {children}
            <Footer />
        </div>
    );
};

export default LegalContainer;
