import ImprintContent from "components/ImprintContent";
import LegalContainer from "layouts/LegalContainer";

const Imprint = () => {
    return (
        <LegalContainer>
            <ImprintContent />
        </LegalContainer>
    );
};

export default Imprint;
