/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import eventActionService from "../actions/events";

import type { TCertificateEventState } from "./types/event.types";

const initialState: TCertificateEventState = {
    loadingState: "unfetched",
    event: {},
};

const eventSlice = createSlice({
    name: "event",
    initialState,
    reducers: {},
    extraReducers: (b) =>
        b
            .addCase(eventActionService.getEvent.fulfilled, (_, action) => ({
                loadingState: "fulfilled",
                event: action.payload,
            }))
            .addCase(eventActionService.getEvent.pending, (state) => ({ loadingState: "pending", event: state.event }))
            .addCase(eventActionService.getEvent.rejected, () => initialState)
            .addCase(eventActionService.getEventDetails.fulfilled, (state, action) => ({
                ...state,
                event: { ...state.event, ...action.payload },
            })),
});

export default eventSlice.reducer;
