import { FC } from "react";

import A4LandscapePreview from "../../../../assets/images/preview-pictures/A4LandscapePreview";
import A4PortraitPreview from "../../../../assets/images/preview-pictures/A4PortraitPreview";
import BadgePreview from "../../../../assets/images/preview-pictures/BadgePreview";
import UsLetterLandscapePreview from "../../../../assets/images/preview-pictures/UsLetterLandscapePreview";
import UsLetterPortraitPreview from "../../../../assets/images/preview-pictures/UsLetterPortraitPreview";
import { EBadgeFormat } from "../../../../types/enums";

type Props = { certificateType: EBadgeFormat | undefined; image: string; disabled: boolean };

const GetCertificatePreview: FC<Props> = ({ certificateType, image, disabled }) => {
    switch (certificateType) {
        case EBadgeFormat.BADGE_1_TO_1:
            return <BadgePreview img={image} disabled={disabled} />;
        case EBadgeFormat.US_LETTER_LANDSCAPE:
            return <UsLetterLandscapePreview img={image} disabled={disabled} />;
        case EBadgeFormat.US_LETTER_PORTRAIT:
            return <UsLetterPortraitPreview img={image} disabled={disabled} />;
        case EBadgeFormat.A4_LANDSCAPE:
            return <A4LandscapePreview img={image} disabled={disabled} />;
        case EBadgeFormat.A4_PORTRAIT:
            return <A4PortraitPreview img={image} disabled={disabled} />;
        default:
            return <UsLetterPortraitPreview img={image} disabled={disabled} />;
    }
};
export default GetCertificatePreview;
