import { theme } from "utils/Theme/theme";

export const infosText = {
    minWidth: 200,
    [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(1 / 2),
    },
};

export const infoRow = {
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
    },
};
