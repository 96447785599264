import type { FC } from "react";
import CertificateValidatorPreview from "features/certificateValidator/components/CertificateValidatorPreview";

import useDeviceBreakpoint from "utils/useDeviceBreakpoint/useDeviceBreakpoint";

import { Stack } from "@mui/material";

import Spinner from "components/Spinner";

import useLoadingThunk from "../../../../redux/hooks/loading";
import CertificateDetails from "../CertificateDetails";

import MultiCertificatePreviewWrapper from "./MultiCertificatePreviewWrapper";

const CertificateDownloadContainer: FC = () => {
    const { isFulfilled } = useLoadingThunk();

    const { isTablet } = useDeviceBreakpoint();

    return (
        <>
            {!isFulfilled("certificates") ? (
                <Spinner />
            ) : (
                <Stack
                    direction={{
                        xs: "column",
                        md: "row",
                    }}
                    flex="1"
                    justifyContent="center"
                >
                    {isTablet && (
                        <MultiCertificatePreviewWrapper>
                            <CertificateValidatorPreview />
                        </MultiCertificatePreviewWrapper>
                    )}
                    <Stack width={{ xs: "100%", md: 600 }} justifyContent="center">
                        <CertificateDetails />
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default CertificateDownloadContainer;
