import { capitalizeFirstLetter } from "utils/strings.helpers";

import { EAccountType } from "../../types/enums";

/**
 * This file contains methods to return values based on the requesting brand, supporting:
 *   - virtualbadge
 *
 * The information is stored within the store in `authForm.user.organisation.account_type`.
 * Each component that conditionally renders can hold a reference to the BrandManager function
 * Use with:
 *
 * ```
 *  import { useBrand } from "../../utils/customHooks";
 *
 *  function Component(props: Props): ReactElement {
 *    const BrandManagerRef = useBrand();
 *
 *    return (
 *      <img
 *       ...
 *       width={BrandManagerRef.getLogoWidth()}
 *      />
 *    )}
 * ```
 */

const BRAND_VALUES = {
    brandNameWithTld: "virtualbadge.io",
    logo: {
        fileNameLight: "vb-logo-light.svg",
        fileNameDark: "vb-logo-dark.svg",
        width: 160,
    },
    headerColor: "#08213D",
    productHomepage: "https://virtualbadge.io",
    facebookAppId: "854674538695088",
    linkedinAppId: "77smr4wvbqauzh",
    legalLinks: {
        imprint: "https://www.virtualbadge.io/imprint",
        terms: "https://www.virtualbadge.io/terms",
        privacy: "https://www.virtualbadge.io/privacy-policy",
    },
    htmlHead: {
        pageTitle: "Access Certificate",
        favicon: {
            icon: "favicon.ico",
            anySize: "favicon.svg",
            appleTouch: "apple-touch-icon-180x180.png",
        },
    },
};

export const getBrandFromLocation = (): EAccountType => EAccountType.vb;

const BrandManager = () => {
    const account_type = "virtualbadge";

    const getBrandFromAccountType = (): EAccountType => EAccountType.vb;

    const getLogoFileName = (scheme: "light" | "dark"): string => {
        if (scheme === "light") return BRAND_VALUES.logo.fileNameLight;
        if (scheme === "dark") return BRAND_VALUES.logo.fileNameDark;
        return BRAND_VALUES.logo.fileNameDark;
    };

    const getBrandNameWithTld = (): string => BRAND_VALUES.brandNameWithTld;

    const getBrandNameWithTldUppercased = (): string => capitalizeFirstLetter(BRAND_VALUES.brandNameWithTld);

    const getHeaderColor = (): string => BRAND_VALUES.headerColor;

    const getProductHomepage = (): string => BRAND_VALUES.productHomepage;

    const getLogoWidth = (): number => BRAND_VALUES.logo.width;

    const getLegalLinks = () => BRAND_VALUES.legalLinks;

    const getFacebookAppId = (): string => BRAND_VALUES.facebookAppId;

    const getLinkedinAppId = (): string => BRAND_VALUES.linkedinAppId;

    const getHtmlHead = () => BRAND_VALUES.htmlHead;

    const getAPIUrl = () => process.env.API_URL;

    return {
        account_type,
        getBrandFromAccountType,
        getLogoFileName,
        getBrandNameWithTld,
        getBrandNameWithTldUppercased,
        getHeaderColor,
        getProductHomepage,
        getLogoWidth,
        getLegalLinks,
        getFacebookAppId,
        getLinkedinAppId,
        getHtmlHead,
        getAPIUrl,
    };
};

export default BrandManager;
