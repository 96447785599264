import type { StoreType } from "polotno/model/store";

/**
 * Checks if the given store has any rich text elements.
 *
 * We have to disable rich text mode for all old events to avoid unexpected side effects.
 *
 * @param store - The store to check.
 * @returns A boolean indicating whether the store has any rich text elements.
 */
export const checkForRichText = (store: StoreType | PolotnoDesigner.PolotnoBadgePropsData): boolean =>
    store.pages.some((page) =>
        page.children.some(
            (child) => child.type === "text" && typeof child.text === "string" && /<[a-z][\s\S]*>/i.test(child.text)
        )
    );
