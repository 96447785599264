import React from "react";

import Typography from "components/shared/Typography";

const OrdredList: React.FC<Omit<IHtmlListProps, "list">> = ({ type, children }) => {
    if (type === "ol") {
        return <ol>{children}</ol>;
    }
    return <ul>{children}</ul>;
};

const HtmlList: React.FC<IHtmlListProps> = ({ type, list }) => {
    return (
        <OrdredList type={type}>
            {list.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Typography key={index}>
                    <li>{item}</li>
                </Typography>
            ))}
        </OrdredList>
    );
};

export default HtmlList;
