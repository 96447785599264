import { BLACK, theme } from "utils/Theme/theme";

export const BackArrowContainer = {
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    margin: theme.spacing(6, 0, 4, 0),
    gap: theme.spacing(1),
    cursor: "pointer",
};

export const iconStyle = {
    color: BLACK,
    cursor: "pointer",
};
