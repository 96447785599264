import { DARK_GREY, LIGHT_WHITE, WHITE } from "utils/Theme/theme";

export const coockieTable = {
    width: "100%",
    overflowY: "auto",
    margin: "2rem 0",
    "& table": { borderCollapse: "collapse !important", width: "100%" },
    "& td, & th": {
        border: `1px solid ${DARK_GREY}`,
        textAlign: "left",
        padding: "8px !important",
    },
    "& th": {
        backgroundColor: DARK_GREY,
        borderColor: WHITE,
    },
    "& tr:nth-child(even)": {
        backgroundColor: LIGHT_WHITE,
    },
};
