export const tokenInput = {
    maxWidth: 480,
    ".MuiOutlinedInput-root": { padding: 0 },
};

export const inputContainer = {
    marginLeft: 0,
};

export const verifyButton = {
    borderRadius: "0px 4px 4px 0px",
};
