import { Divider, styled } from "@mui/material";

import certificatesActionService from "../../redux/actions/certificates";
import certificatesSelectorService from "../../redux/selector/certificates";
import { useAppDispatch, useAppSelector } from "../../redux/store";

import CertificateOverviewDownload from "./CertificateOverview/CertificateOverviewDownload";
import CertificateOverviewHeader from "./CertificateOverview/CertificateOverviewHeader";
import CertificateOverviewItem from "./CertificateOverview/CertificateOverviewItem";

const OverviewContainer = styled("div")(() => ({
    width: "100%",
    maxWidth: "1200px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "flex-start",
}));

const OverviewItems = styled("div")(({ theme }) => ({
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, 286px)",
    gridTemplateRows: "auto",
    gap: "10px",
    [theme.breakpoints.up("md")]: {
        justifyContent: "space-evenly",
        flexDirection: "row",
    },
    [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
        alignItems: "center",
        flexDirection: "column",
    },
}));

const CertificateDownloadOverview = () => {
    const dispatch = useAppDispatch();
    const certificates = useAppSelector(certificatesSelectorService.selectCertificates);

    const handleClickPreview = (index: number) => {
        localStorage.setItem("last_viewed_certificate", certificates.data[index].certificate_id);
        dispatch(certificatesActionService.selectCertificate(index));
    };

    return (
        <OverviewContainer>
            <CertificateOverviewHeader />
            <Divider style={{ width: "100%" }} />
            <CertificateOverviewDownload />
            <OverviewItems>
                {certificates.data.map((cert, index) => (
                    <CertificateOverviewItem
                        certificate={cert}
                        key={cert.certificate_id}
                        onClick={() => handleClickPreview(index)}
                    />
                ))}
            </OverviewItems>
        </OverviewContainer>
    );
};

export default CertificateDownloadOverview;
