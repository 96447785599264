import { theme } from "utils/Theme/theme";

export const consentOverlay = {
    backgroundColor: "rgba(0, 0, 0, .6)",
};

export const consentMainStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.grey[100],
    color: "#262626",
    maxWidth: "640px",
    borderRadius: "4px",
    fontSize: "15px",
    justifyContent: "flex-end",
};

export const consentButton = {
    background: theme.palette.primary.light,
    color: "#ffffff",
    fontSize: "14px",
    borderRadius: "8px",
    padding: "8px 36px",
};

export const consentDeclineButton = {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: "8px",
    backgroundColor: "rgba(0,0,0,0)",
    color: theme.palette.grey[600],
    fontSize: "14px",
    padding: "8px 36px",
};

export const bannerHeader = {
    padding: "6px 0",
    textAlign: "start",
    color: theme.palette.grey[900],
    fontWeight: 500,
};

export const bannerLink = {
    color: theme.palette.grey[600],
};

export const linkStyle = {
    color: theme.palette.grey[500],
    fontSize: "14px",
    marginRight: "16px",
    marginTop: 8,
};

export const bannerTabsWrapper = {
    backgroundColor: theme.palette.grey[50],
    boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.15)",
    borderRadius: "2px",
    marginTop: theme.spacing(3),
};

export const tabsWrapper = {
    backgroundColor: theme.palette.grey[50],
    fontSize: "15px",
};
