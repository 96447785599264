import { Trans } from "react-i18next";
import { useGetCurrentSelectedCertificate } from "redux/hooks/badge";

import useTranslation from "i18n/hooks/useTranslation";
import { downloadFile } from "utils/misc";
import sanitizeFileName from "utils/sanitizeFileName";

import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton, Link } from "@mui/material";

import Typography from "components/shared/Typography";

interface IShareInstagramModalProps {
    dialogOpen: boolean;
    handleDialogClose: () => void;
}

const ShareInstagramModal: React.FC<IShareInstagramModalProps> = ({ dialogOpen, handleDialogClose }) => {
    const t = useTranslation();
    const selectedCertificate = useGetCurrentSelectedCertificate();

    const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const instagramURL = isMobileDevice ? "instagram://app" : "https://www.instagram.com/";

    const handleDownloadCertificate = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();

        downloadFile(
            selectedCertificate.badge_image_url as string,
            sanitizeFileName(selectedCertificate.certificate_id.substring(0, 4), {
                prefix: selectedCertificate.recipient_email.split("@")[0],
                fileType: "png",
            })
        );
    };

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title" display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5" m={0}>
                    {t("certificate_download.share_certificate.instagram_modal.title")}
                </Typography>
                <IconButton size="small" onClick={handleDialogClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1">
                    {t("certificate_download.share_certificate.instagram_modal.description")}
                    <ol>
                        <li>
                            <Trans
                                i18nKey="certificate_download.share_certificate.instagram_modal.step_one"
                                components={{
                                    1: (
                                        <Link
                                            href={selectedCertificate.badge_image_url as string}
                                            fontWeight="bold"
                                            underline="none"
                                            onClick={handleDownloadCertificate}
                                        />
                                    ),
                                }}
                            />
                        </li>
                        <li>
                            <Trans
                                i18nKey="certificate_download.share_certificate.instagram_modal.step_two"
                                components={{
                                    1: <Link href={instagramURL} fontWeight="bold" underline="none" target="_blank" />,
                                }}
                            />{" "}
                        </li>
                        <li>{t("certificate_download.share_certificate.instagram_modal.step_three")}</li>
                    </ol>
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

export default ShareInstagramModal;
