import type { FC, PropsWithChildren } from "react";

import NotificationProvider from "../NotificationProvider/NotificationProvider";

import useRedirection from "./redirect.helper";

const RedirectProvider: FC<PropsWithChildren> = ({ children }) => {
    useRedirection();

    return <NotificationProvider>{children}</NotificationProvider>;
};

export default RedirectProvider;
