/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
// Utils
import { isFunction } from "lodash";
import uniqBy from "lodash/uniqBy";
// Image sharpening
import _pica from "pica";

// Config
// eslint-disable-next-line import/no-cycle
import BadgeEngine from "./BadgeEngine";

const pica = _pica();

// * Note: This file is copy pasted from the frontend-user since it will be removed when we move the logic to the BE
// Todo: remove this file since we will create the certificate from the backend

// === Helper functions ===
export const getBadgeObjects = (badgeContext: VbDesigner.BadgeContext) => {
    const { badgeObjectsType, badgeProps } = badgeContext;
    if (!badgeObjectsType || !badgeProps)
        throw new Error("Cannot execute getBadgeObjects, missing badgeObjectsType or badgeProps.");
    switch (badgeObjectsType) {
        case "badgeObjects":
            return badgeProps.badgeObjects;
        case "userInformationObjects":
            return badgeProps.userInformationObjects;
        case "customInputObjects":
        case "customMappingFieldObjects":
            if (badgeProps.customMappingFieldObjects) {
                const concatArray: VbDesigner.BadgeObject[] = [];
                return concatArray.concat(badgeProps.customMappingFieldObjects, badgeProps.customInputObjects);
            }
            return badgeProps.customInputObjects;
        case "all":
        default: {
            const concatArray: VbDesigner.BadgeObject[] = [];
            return concatArray.concat(
                badgeProps.badgeObjects,
                badgeProps.userInformationObjects,
                badgeProps.customInputObjects,
                badgeProps.customMappingFieldObjects
            );
        }
    }
};

/**
 * Wrapper for updating the badge objects for a given badgeObjectsType (i.e. customInputObjects etc.)
 * @param badgeObjects
 * @param badgeObjectsType
 * @param badgeInterface
 */
export const updateBadgeObjects = (badgeObjects: VbDesigner.BadgeObject[], badgeContext: VbDesigner.BadgeContext) => {
    if (!badgeContext) throw new Error("[updateBadgeObjects()] Missing badgeContext");
    // If selected type is custommappingfield, set the key to "customMappingFieldObjects".
    // This is necessarry because the arrays customMappingFieldObjects and customInputObjects get
    // concatinated when getBadgeObject() is called at the start of the handler. (This needs to be done in order
    // to properly switch selection between the customMappingFieldObjects and customInputObjects.
    // In case all badge objects should be selectable, filter for the selected type and update the corresponsing array.
    let badgeObjectsTypeKey = badgeContext.badgeObjectsType;

    if (["customMappingFieldObjects", "customInputObjects", "all"].includes(badgeContext.badgeObjectsType as string)) {
        let switchCase = badgeContext.canvasState?.selectedType as string;
        if (badgeContext.selectedElementObject) {
            switchCase = badgeContext.selectedElementObject.type;

            // Since the user-name and user-profile-pic are both of the generic type image or text we have to use
            // their id to identify them in the switch case.
            if (["user-name", "user-profile-pic"].includes(badgeContext.selectedElementObject.id))
                switchCase = badgeContext.selectedElementObject.id;
        }
        switch (switchCase) {
            case "text":
            case "image":
            case "shape":
                badgeObjectsTypeKey = "badgeObjects";
                // Filter for all fields that should reside in the "badgeObjects" array
                badgeObjects = badgeObjects.filter(
                    (bObj: VbDesigner.BadgeObject) =>
                        ["text", "image", "shape", "background"].includes(bObj.type) &&
                        !["user-profile-pic", "user-name"].includes(bObj.id)
                );
                break;
            case "user-profile-pic":
            case "user-name":
                badgeObjectsTypeKey = "userInformationObjects";
                // Filter for all fields that should reside in the "userInformationObjects" array
                badgeObjects = badgeObjects.filter((bObj: VbDesigner.BadgeObject) =>
                    ["user-profile-pic", "user-name"].includes(bObj.id)
                );
                break;
            case "dropdown":
            case "textinput":
                badgeObjectsTypeKey = "customInputObjects";
                // Filter for all fields that should reside in the "customInputObjects" array
                badgeObjects = badgeObjects.filter((bObj: VbDesigner.BadgeObject) =>
                    ["dropdown", "textinput"].includes(bObj.type)
                );
                break;
            case "custommappingfield":
            default:
                badgeObjectsTypeKey = "customMappingFieldObjects";
                // Filter for all fields that should reside in the "customMappingFieldObjects" array
                badgeObjects = badgeObjects.filter(
                    (bObj: VbDesigner.BadgeObject) => bObj.type === "custommappingfield"
                );
                break;
        }

        // Check for duplicates
        badgeObjects = uniqBy(badgeObjects, (e: VbDesigner.BadgeObject) => {
            return e.id;
        });
    }

    badgeContext.badgeProps = {
        ...badgeContext.badgeProps,
        [badgeObjectsTypeKey as VbDesigner.BadgeObjectsType]: badgeObjects,
    } as VbDesigner.IBadgeProps;

    // If the update call is made with the inputs interface (i.e. made by triggering changes in the badge inputs)
    // Save the badgeprops immediately. In case of the interface beein the canvasInterface, we dont want to update
    // the store on every updateBadgeObjects call to save performance.
    // if (badgeContext.interfaceType === "badgeInputsInterface" && badgeContext.setBadgeProps) {
    if (isFunction(badgeContext.setBadgeProps)) badgeContext.setBadgeProps(badgeContext.badgeProps);
    // }
};

/**
 * Get the hoizontal text alignment offset from a text element (already scaled). This is necessary in order to have proper positioning of the hitboxes of elements.
 * @param badgeObject
 * @param canvasScale
 * @returns {number} horizontalTextAlignOffset
 */
export const getHorizontalTextAlignmentOffset = (badgeObject: VbDesigner.BadgeObject, canvasScale: number): number => {
    let horizontalTextAlignOffset = 0;

    if (["textinput", "dropdown", "text", "custommappingfield"].includes(badgeObject.type))
        if (badgeObject.textAlignment && badgeObject.text?.split("\n").length === 1) {
            switch (badgeObject.textAlignment) {
                case "left":
                    horizontalTextAlignOffset = 0;
                    break;
                case "center":
                    horizontalTextAlignOffset = (badgeObject.width / 2) * canvasScale;
                    break;
                case "right":
                    horizontalTextAlignOffset = badgeObject.width * canvasScale;
                    break;
                default:
                    horizontalTextAlignOffset = 0;
                    break;
            }
        }
    return horizontalTextAlignOffset;
};

/**
 * Get the index from the currently selected element in the corresponding array (e.g. badgeObjects, userInformationObjects etc.)
 * @param badgeObjects
 * @param selectedElement
 * @returns {number} selectedIndex
 */
export const getCurrentlySelectedElementIndex = (badgeObjects: any, selectedElement: any) => {
    let selectedIndex;
    // eslint-disable-next-line func-names, array-callback-return
    badgeObjects.map(function (obj: VbDesigner.BadgeObject, i: number) {
        if (obj.id === selectedElement) selectedIndex = i;
    });

    return selectedIndex;
};

/**
 * Check if a hit was registered on a badge object
 * @param badgeObject
 * @param options {mouseX, mouseY, canvasScale, canvasWidth}
 * @returns {boolean} hit
 */
export const detectedHitOnBadgeObject = (
    badgeObject: VbDesigner.BadgeObject,
    options: {
        mouseX: number;
        mouseY: number;
        canvasScale: number;
        canvasWidth: number;
    }
): boolean => {
    const { mouseX, mouseY, canvasScale, canvasWidth } = options;
    const horizontalTextAlignOffset = getHorizontalTextAlignmentOffset(badgeObject, canvasScale);

    // Position
    let posX = badgeObject.posX * canvasScale;
    const posY = badgeObject.posY * canvasScale;

    // Width
    const width = badgeObject.width * canvasScale;
    const height = badgeObject.height * canvasScale;

    // Adjust position if an alignment is set
    if (badgeObject.alignment.includes("horizontal")) posX = (canvasWidth / 2) * canvasScale - width / 2;

    const hitDetected =
        mouseX > posX - horizontalTextAlignOffset &&
        mouseX < posX - horizontalTextAlignOffset + width &&
        mouseY > posY - 10 &&
        mouseY < posY + height + 10;

    return hitDetected;
};

/**
 * Check if a hit was registered on a delete icon
 * @param badgeObject
 * @param options
 * @returns {boolean} hit
 */
export const detectedHitOnDeleteIcon = (
    badgeObject: VbDesigner.BadgeObject,
    options: {
        mouseX: number;
        mouseY: number;
        canvasScale: number;
        canvasWidth: number;
    }
): boolean => {
    const { mouseX, mouseY, canvasScale, canvasWidth } = options;

    // Prevent deleting usename or profile pic
    if (["user-name", "user-profile-pic"].includes(badgeObject.id)) return false;

    const horizontalTextAlignOffset = getHorizontalTextAlignmentOffset(badgeObject, canvasScale);

    // Position
    let posX = badgeObject.posX * canvasScale;
    const posY = badgeObject.posY * canvasScale;

    // Width
    const width = badgeObject.width * canvasScale;

    // Adjust position if an alignment is set
    if (badgeObject.alignment.includes("horizontal")) posX = (canvasWidth / 2) * canvasScale - width / 2;

    return (
        mouseX > posX - horizontalTextAlignOffset + width &&
        mouseX < posX - horizontalTextAlignOffset + width + 20 &&
        mouseY > posY - 20 &&
        mouseY < posY
    );
};

/**
 *
 * @param badgeObject
 * @param options
 * @returns
 */
export const detectedHitOnScalingIcon = (
    badgeObject: VbDesigner.BadgeObject,
    options: {
        mouseX: number;
        mouseY: number;
        canvasScale: number;
        isScaling: boolean;
    }
) => {
    const { mouseX, mouseY, canvasScale, isScaling } = options;

    const horizontalTextAlignOffset = getHorizontalTextAlignmentOffset(badgeObject, canvasScale);

    // Position
    const posX = badgeObject.posX * canvasScale;
    const posY = badgeObject.posY * canvasScale;

    // Width/Height
    const width = badgeObject.width * canvasScale;
    const height = badgeObject.height * canvasScale;

    const scalingHitBoxOffsetMin = isScaling ? 999 : 10;
    const scalingHitBoxOffsetMax = isScaling ? 999 : 30;

    return (
        mouseX <
            badgeObject.posX * canvasScale -
                horizontalTextAlignOffset +
                badgeObject.width * canvasScale +
                scalingHitBoxOffsetMax &&
        mouseX > posX - horizontalTextAlignOffset + width - scalingHitBoxOffsetMin &&
        mouseY < posY + height + scalingHitBoxOffsetMax &&
        mouseY > posY + height - scalingHitBoxOffsetMin
    );
};

/**
 * Check if a hit was registered on a duplicate icon
 * @param badgeObject
 * @param mouseX
 * @param mouseY
 * @param canvasScale
 * @returns {boolean} hit
 */
export const detectedHitOnDuplicateIcon = (
    badgeObject: VbDesigner.BadgeObject,
    options: {
        mouseX: number;
        mouseY: number;
        canvasScale: number;
        canvasWidth: number;
    }
): boolean => {
    const { mouseX, mouseY, canvasScale, canvasWidth } = options;

    // Prevent duplicating usename or profile pic
    if (["user-name", "user-profile-pic"].includes(badgeObject.id)) return false;

    const horizontalTextAlignOffset = getHorizontalTextAlignmentOffset(badgeObject, canvasScale);

    // Position
    let posX = badgeObject.posX * canvasScale;
    const posY = badgeObject.posY * canvasScale;

    // Width
    const width = badgeObject.width * canvasScale;

    // Adjust position if an alignment is set
    if (badgeObject.alignment.includes("horizontal")) posX = (canvasWidth / 2) * canvasScale - width / 2;
    // if (badgeObject.alignment.includes("vertical")) posY = canvasWidth / 2 * canvasScale + height / 2;

    return (
        mouseX > posX - horizontalTextAlignOffset + width - 20 &&
        mouseX < posX - horizontalTextAlignOffset + width + 20 &&
        mouseY > posY - 20 &&
        mouseY < posY
    );
};

/**
 * Return the greatest common divisor for 2 given numbers.
 * Useful for example calculating the aspect ratio.
 * @param a
 * @param b
 * @returns greatest common divisor (number)
 */
export const getGreatestCommonDivisor = function (a: number, b: number): number {
    if (!b) {
        return a;
    }
    return getGreatestCommonDivisor(b, a % b);
};

/**
 * Adds/removes an item based on its existance in an array.
 * Returns the array.
 * @param arr
 * @param item
 * @returns
 */
export const toggleArrayItem = (arr: any[], item: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    arr.includes(item)
        ? (arr = arr.filter((i) => i !== item)) // remove item
        : (arr = [...arr, item]); // add item
    return arr;
};

/**
 * Deserialize one image object from a blob url or from a bade64 string
 * @param imageObject
 */
export const deserializeImageObject = (imageObject: VbDesigner.BadgeObject, smoothResize = false) => {
    const newImage = new Image();
    newImage.src = (imageObject.imgBlobUrl ? imageObject.imgBlobUrl : imageObject.imgBase64) ?? "";
    newImage.crossOrigin = "anonymous";
    newImage.onload = async () => {
        imageObject.imgExport = newImage;
        // Use smoother resized version for preview in user panel
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (smoothResize) await resizeImage(newImage, imageObject.width, imageObject.height);
        // Use high quality image for admin panel
        // eslint-disable-next-line no-multi-assign
        else imageObject.img = imageObject.img = newImage;
    };
};

export const adjustSizeValues = (obj: VbDesigner.BadgeObject) => {
    try {
        if (!obj) return;
        if (obj.textSize && typeof obj.textSize === "string")
            obj.textSize = parseInt((obj.textSize as string).replace("px", ""), 10);
        if (obj.textSizeBase && typeof obj.textSizeBase === "string")
            obj.textSizeBase = parseInt((obj.textSizeBase as string).replace("px", ""), 10);
        if (obj.textSpacing && typeof obj.textSpacing === "string")
            obj.textSpacing = parseInt((obj.textSpacing as string).replace("px", ""), 10);
    } catch (err) {
        console.trace("adjustSizeValues()", err);
    }
};

/**
 * ### Deserialize badgeProps:
 * - convert all image urls to images
 * - wait for the images to be completed
 * - round all number values to 2 after decimal point
 * @param badgeProps
 * @returns badgeProps
 */
export const deserializeBadgeProps = async (
    badgeProps: VbDesigner.IBadgeProps | null,
    format: VbDesigner.BadgeFormat
) => {
    if (!badgeProps) return null;
    const badgeEngine = new BadgeEngine(format);

    badgeProps.badgeObjects.forEach(async (obj) => {
        if (obj.type === "image" || obj.type == "background") {
            await deserializeImageObject(obj);
        }
        if (obj.type === "text") await badgeEngine.updateTextDimensions(obj);

        adjustSizeValues(obj);
    });
    badgeProps.userInformationObjects.forEach(async (obj) => {
        if (obj.type === "image") {
            await deserializeImageObject(obj);
        }
        if (obj.id === "user-name") await badgeEngine.updateTextDimensions(obj);

        adjustSizeValues(obj);
    });
    badgeProps.customInputObjects.forEach(async (obj) => {
        if (["textinput", "dropdown"].includes(obj.type)) await badgeEngine.updateTextDimensions(obj);

        adjustSizeValues(obj);
    });
    badgeProps.customMappingFieldObjects.forEach(async (obj) => {
        if (obj.type === "custommappingfield") await badgeEngine.updateTextDimensions(obj);

        adjustSizeValues(obj);
    });

    // Wait for all images to be completely loaded
    const loadedSuccessfully = await new Promise((resolve) => {
        const loadInterval = setInterval(() => {
            let foundIncompleteImage = false;
            badgeProps.badgeObjects.forEach((obj) => {
                if (["background", "image"].includes(obj.type)) {
                    const objImage = obj.img as HTMLImageElement;
                    if (objImage) if (!objImage.complete) foundIncompleteImage = true;
                }
            });
            if (!foundIncompleteImage) {
                clearInterval(loadInterval);
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                clearTimeout(loadingTimeout);
                resolve(true);
            }
        }, 1000);

        // Set a timeout of 60s for image loading. Timeout results in a soft error.
        const loadingTimeout = setTimeout(() => {
            clearInterval(loadInterval);
            console.error("Loading the certificate images exceeded the 60s timeout");
            resolve(false);
        }, 60000);
    });

    if (!loadedSuccessfully) return null;
    return badgeProps;
};

/**
 * Resize function to eliminate aliasing issues when scaling down images.
 * Returns an image object.
 * @param img
 * @param targetWidth
 * @param targetHeight
 * @returns
 */
export const resizeImage = (
    img: HTMLImageElement,
    targetWidth: number,
    targetHeight: number
): Promise<HTMLImageElement> => {
    const canvas = document.createElement("canvas");
    canvas.width = targetWidth || 1;
    canvas.height = targetHeight || 1;

    return new Promise((resolve) => {
        pica.resize(img, canvas, { unsharpAmount: 150, unsharpRadius: 0.5, unsharpThreshold: 0.5 })
            .then((result: HTMLCanvasElement) => pica.toBlob(result, "image/png", 0.9))
            .then((blob: Blob) => {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const img = new Image();
                img.onload = () => {
                    resolve(img);
                };
                img.src = URL.createObjectURL(blob);
            });
    });
};
