import { SnackbarMessage } from "notistack";

import { Box, LinearProgress, Typography } from "@mui/material";

import controlSelectorService from "../../../../redux/selector/control";
import { useAppSelector } from "../../../../redux/store";

type Props = {
    message: SnackbarMessage;
};

const DownloadProgress = ({ message }: Props) => {
    const downloadProgress = useAppSelector(controlSelectorService.selectDownloadProgress);

    const caluculatePercentalProgress = (100 / downloadProgress.total) * downloadProgress.current;

    return (
        <div style={{ minWidth: 300, textAlign: "center" }}>
            {message}
            <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                    <LinearProgress variant="determinate" value={Math.floor(caluculatePercentalProgress)} />
                </Box>
                <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">{`${Math.floor(
                        caluculatePercentalProgress
                    )}%`}</Typography>
                </Box>
            </Box>
        </div>
    );
};

export default DownloadProgress;
