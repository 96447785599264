import TermsContent from "components/TermsContent";
import LegalContainer from "layouts/LegalContainer";

export default function Terms() {
    return (
        <LegalContainer>
            <TermsContent />
        </LegalContainer>
    );
}
