/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useNavigate } from "react-router-dom";

import { theme } from "utils/Theme/theme";
/**
 * * this component is used to translate the link component because
 * * i18n does not support a/Link components directly inside the Trans component
 * @interface ILinkTranslatedProps
 * @param {string} moveTo - path to move to
 * @param {React.ReactNode} children - children to render
 * @returns {JSX.Element}
 */
const LinkTranslated: React.FC<ILinkTranslatedProps & JSX.IntrinsicElements["div"]> = ({
    moveTo,
    children,
    ...rest
}) => {
    const navigate = useNavigate();
    return (
        <span
            style={{
                color: theme.palette.primary.dark,
                textDecoration: "underline",
                fontWeight: 700,
            }}
            onClick={() => navigate(moveTo)}
            {...rest}
        >
            {children}
        </span>
    );
};

export default LinkTranslated;
