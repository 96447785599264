export const CertificateDownloaderApiRoutes = {
    getDetails: (token: string) => `${process.env.API_URL}/api/public/get_pdf_info/${token}`,
    postStatsDownload: (
        orga_slug: string,
        certificate_slug: string,
        token: string,
        certificate_id: string,
        stats_type: string
    ) =>
        `/api/public/participant?orga_slug=${orga_slug}&event_slug=${certificate_slug}&token=${token}&certificate_id=${certificate_id}&${stats_type}=1`,
    uploadCertificateToBlob: `/api/public/upload_blob`,
    getLinkedInAccessToken: (linkedin_access_token: string) =>
        `/api/public/linkedin_profile?access_token=${linkedin_access_token}`,
    getFacebookAccessToken: (facebook_access_token: string) =>
        `https://graph.facebook.com/v8.0/me?access_token=${facebook_access_token}`,
    getFacebookProfilePicture: (facebook_access_token: string) =>
        `https://graph.facebook.com/v8.0/me/picture?width=800&height=800&type=square&redirect=false&access_token=${facebook_access_token}`,
    postStatsShare: (
        orga_slug: string,
        certificate_slug: string,
        token: string,
        certificate_id: string,
        stats_counter: string
    ) =>
        `/api/public/participant?orga_slug=${orga_slug}&event_slug=${certificate_slug}&token=${token}&certificate_id=${certificate_id}&${stats_counter}=1`,
    getTwitterRequestToken: "/api/public/twitter_request_token",
    shareLinkedin: "/api/public/linkedin_share",
    shareTwitter: "/api/public/twitter_share",
};
