import type { FC, PropsWithChildren } from "react";

import certificatesSelectorService from "../../../../redux/selector/certificates";
import { useAppSelector } from "../../../../redux/store";

import MultiCertificateSlider from "./MultiCertificateSlider/MultiCertificateSlider";

const MultiCertificatePreviewWrapper: FC<PropsWithChildren> = ({ children }) => {
    const isSingleCertificate = useAppSelector(certificatesSelectorService.isSingleCertificate);

    if (isSingleCertificate) return <>{children}</>;
    return (
        <div>
            <MultiCertificateSlider />
            {children}
        </div>
    );
};

export default MultiCertificatePreviewWrapper;
