import React from "react";

import useTranslation from "i18n/hooks/useTranslation";

import { Paper, Tab, Tabs } from "@mui/material";

import TabPanel from "components/TabPanel";

import { a11yProps } from "./CookiesBanner.helpers";
import * as styles from "./CookiesBanner.styles";

export default function DisabledTabs() {
    const t = useTranslation();

    const [value, setValue] = React.useState(0);
    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div style={styles.bannerTabsWrapper}>
            <Paper square>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                    style={styles.tabsWrapper}
                >
                    <Tab label={t("common.cookie_banner.tab_area.cookie_type_one_name")} {...a11yProps(0)} />
                    <Tab label={t("common.cookie_banner.tab_area.cookie_type_two_name")} {...a11yProps(1)} />
                </Tabs>
            </Paper>
            <TabPanel value={value} index={0}>
                {t("common.cookie_banner.tab_area.cookie_type_one_description")}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {t("common.cookie_banner.tab_area.cookie_type_two_description")}
            </TabPanel>
        </div>
    );
}
