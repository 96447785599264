import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { EEventType } from "../../types/enums";
import { selectStore } from "../store";

const selectEvent = createDraftSafeSelector(selectStore, (store) => store.event);

const selectEventLoadingState = createDraftSafeSelector(selectEvent, (event) => event.loadingState);

const eventIsFetched = createDraftSafeSelector(selectEventLoadingState, (loadingState) => loadingState === "fulfilled");

const eventIsFetching = createDraftSafeSelector(selectEventLoadingState, (loadingState) => loadingState === "pending");

const eventFetchError = createDraftSafeSelector(selectEventLoadingState, (loadingState) => loadingState === "rejected");

const eventNotInitialFetched = createDraftSafeSelector(
    selectEventLoadingState,
    (loadingState) => loadingState === "unfetched"
);

const selectFetchedEvent = createDraftSafeSelector(selectEvent, (event) => event.event);

const eventSocialMediaModeEnabled = createDraftSafeSelector(
    selectFetchedEvent,
    (currEvent) => currEvent.event_social_media_mode_enabled || false
);

const selectEventDates = createDraftSafeSelector(selectFetchedEvent, (event) => ({
    issueDate: event.issue_date,
    expirationDate: event.expiration_date,
}));

const eventIsCertificate = createDraftSafeSelector(
    selectFetchedEvent,
    (event) => event.event_type === EEventType.CERTIFICATE
);

const eventSelectorService = {
    selectEvent,
    selectEventLoadingState,
    eventIsFetched,
    eventIsFetching,
    eventFetchError,
    eventNotInitialFetched,
    selectFetchedEvent,
    eventSocialMediaModeEnabled,
    selectEventDates,
    eventIsCertificate,
};

export default eventSelectorService;
