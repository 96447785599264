/* eslint-disable no-console */
import { AxiosError, type AxiosRequestConfig } from "axios";

import { axios } from "../../lib/axios";

/** Fetch helper for axios-get calls */
export const fetcher = async <ResponseType = unknown>(
    url: string,
    requestConfig?: AxiosRequestConfig<unknown>,
    errorCallback?: (err: AxiosError) => void
): Promise<ResponseType | null> =>
    axios
        .get<ResponseType>(url, { ...requestConfig })
        .then((res) => res.data)
        .catch((err: AxiosError) => {
            console.error(err);
            if (errorCallback) errorCallback(err);
            return null;
        });
