/* eslint-disable no-console */
import { Component } from "react";

import { trackException } from "utils/azureApplicationInsights";

import { Box } from "@mui/material";

import errorBoundaryImg from "assets/images/undraw_warning_cyit.svg";
import Typography from "components/shared/Typography";

import type { IPropsWithChildren } from "../../types/global";

import * as styles from "./ErrorBoundary.styles";

class ErrorBoundary extends Component<IPropsWithChildren, { hasError: boolean }> {
    constructor(props: IPropsWithChildren) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error) {
        console.error(`error: ${error}`);
        trackException(error, { errorInfo: error.message, componentStack: error.stack }, true);
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            // You can render any custom fallback UI
            return (
                <Box sx={styles.errorContainer}>
                    <Box sx={styles.innerContainer}>
                        <Typography variant="h1">Something went wrong ¯\_(ツ)_/¯</Typography>
                        <Typography>Please try again later</Typography>
                        <img style={styles.image} src={errorBoundaryImg} width="50%" alt="error occured" />
                    </Box>
                </Box>
            );
        }
        return children;
    }
}

export default ErrorBoundary;
