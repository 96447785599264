import React from "react";
import { Formik, FormikHelpers } from "formik";
import { isEmpty } from "lodash";

import useTranslation from "i18n/hooks/useTranslation";

import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export interface FormDialogProps {
    children?: string | JSX.Element;
    dialogOpen: boolean;
    onClose: React.MouseEventHandler<HTMLButtonElement>;
    onSubmit: (val: any, formikHelpers?: FormikHelpers<any>) => void;
    dialogOkText?: string | JSX.Element;
    CustomSaveButton?: JSX.Element;
    dialogTitleText?: string | JSX.Element;
    dialogCancelText?: string | JSX.Element;
    initialValues: any;
    validationSchema?: any;
    fullscreen?: boolean;
}

export default function FormDialog({
    children,
    dialogOpen,
    onClose,
    onSubmit,
    dialogOkText,
    CustomSaveButton,
    dialogTitleText,
    dialogCancelText,
    initialValues,
    validationSchema,
    fullscreen,
}: FormDialogProps) {
    const t = useTranslation();

    const handleDialogClose = (event: any, reason?: "backdropClick" | "escapeKeyDown") => {
        if (reason && ["backdropClick", "escapeKeyDown"].includes(reason)) return;
        onClose(event);
    };

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            fullScreen={fullscreen}
        >
            <DialogTitle id="alert-dialog-title">
                {dialogTitleText && <Typography variant="h5">{dialogTitleText}</Typography>}
            </DialogTitle>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {({ values, errors }) => {
                    return (
                        <>
                            <DialogContent dividers>{children}</DialogContent>
                            <DialogActions sx={{ display: "flex", gap: 2 }}>
                                <Button onClick={onClose}>
                                    <Typography variant="body1">{dialogCancelText || t("common.cancel")}</Typography>
                                </Button>
                                {CustomSaveButton ? (
                                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                    <div
                                        onClick={() => {
                                            if (isEmpty(errors)) onSubmit(values);
                                        }}
                                    >
                                        {CustomSaveButton}
                                    </div>
                                ) : (
                                    <Button
                                        onClick={() => {
                                            if (isEmpty(errors)) onSubmit(values);
                                        }}
                                    >
                                        {dialogOkText || t("Ok")}
                                    </Button>
                                )}
                            </DialogActions>
                        </>
                    );
                }}
            </Formik>
        </Dialog>
    );
}
