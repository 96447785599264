import { FC, memo } from "react";

import { theme } from "utils/Theme/theme";

import { styled } from "@mui/material";

import useWalletButtons from "./walletButton.hooks";
import type { TWalletButtonType } from "./walletButton.types";

type Props = {
    type: TWalletButtonType;
    onClick: () => void;
};

const WalletImage = styled("img")(() => ({
    cursor: "pointer",
    height: "50px",
    [theme.breakpoints.down("sm")]: {
        height: "40px",
    },
}));

const WalletButton: FC<Props> = memo(({ type, onClick }) => {
    const { walletButtonAlt, walletButton } = useWalletButtons();

    return <WalletImage src={walletButton(type)} alt={walletButtonAlt[type]} onClick={onClick} />;
});

WalletButton.displayName = "Wallet button image";

export default WalletButton;
