import Axios, { InternalAxiosRequestConfig } from "axios";

function appRequestInterceptor(config: InternalAxiosRequestConfig) {
    return config;
}

export const axios = Axios.create({
    baseURL: process.env.API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

axios.interceptors.request.use(appRequestInterceptor);
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);
