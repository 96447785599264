import React from "react";

import { Backdrop, Box, CircularProgress } from "@mui/material";

import Footer from "components/Footer";

import useLoadingThunk from "../../redux/hooks/loading";

import * as styles from "./ValidationLayout.styles";

const ValidationLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { isPending, isNotInitialLoaded } = useLoadingThunk();

    const allInformationsAreLoading =
        (isPending("certificates") || isPending("events") || isPending("token")) && !isNotInitialLoaded("token");

    return (
        <Box sx={styles.container}>
            <Box sx={styles.content}>
                <Backdrop
                    open={allInformationsAreLoading}
                    sx={{ backgroundColor: "white", color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                >
                    <CircularProgress />
                </Backdrop>
                {children}
            </Box>
            <Footer withLogo={false} />
        </Box>
    );
};

export default ValidationLayout;
