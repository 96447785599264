import { useShareTwitterCall } from "features/certificateDownload/api/shareApi";
import * as Yup from "yup";

import eventSelectorService from "../../../../redux/selector/events";
import { useAppSelector } from "../../../../redux/store";

export const useShareTwitterModal = () => {
    const { shareTwitter, setPendingState, loadingState, setErrorState } = useShareTwitterCall();
    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);

    const tags =
        (eventDetails?.event_share_hashtags && `#${eventDetails?.event_share_hashtags.split(",").join(" #")}`) || "";

    const validationSchema = { twitterPostDescription: Yup.string().max(280 - tags.length) };
    const initialValues = {
        twitterPostDescription: eventDetails?.event_share_text || "",
        postTags: tags,
    };

    const handleDialogSubmit = async (values: typeof initialValues) => {
        setPendingState();
        const twitterPostDescription = values?.twitterPostDescription.concat("\n", values.postTags);
        await shareTwitter(twitterPostDescription);
    };

    const handleCancel = () => setErrorState();

    return { initialValues, validationSchema, handleDialogSubmit, loadingState, handleCancel };
};
