import { isTypeOf } from "./isTypeOf";

/**
 * Returns boolean if the checked property type matches the given type.
 * Checks if props are given too.
 * @example if (checkPropsType(certificate.badge_props, certificate.badge_props_type, "badge"))
 *              console.log("Certificate is created with Badge Engine");
 * @example if (checkPropsType(certificate.badge_props, certificate.badge_props_type, "polotno"))
 *              console.log("Certificate is created with Polotno");
 * @argument props Stored properties of certificate.
 * @argument propsType Stored properties type of certificate.
 * @argument checksum Property type of certificate to check.
 * @argument noTypeCheck (Optional) Do not check property type. Default false.
 * @returns Is certificate property type same as type to check?
 */
const checkPropsType = (
    props: VbDesigner.IBadgeProps | PolotnoDesigner.PolotnoBadgePropsData | undefined | null,
    propsType: "badge" | "polotno" | undefined | null,
    checksum: "badge" | "polotno",
    noTypeCheck = false
) => {
    // Check if props or propsType is null or undefined
    if (props === null || props === undefined || propsType === null || propsType === undefined) return false;
    // If only check if properties are not null
    if (noTypeCheck) return true;
    // Check if props type is the same as the checksum
    if (propsType !== checksum) return false;
    // Check if props type is correct when checksum is "badge"
    if (checksum === "badge" && propsType === "badge" && isTypeOf<VbDesigner.IBadgeProps>(props)) return true;
    // Check if props type is correct when checksum is "polotno"
    if (checksum === "polotno" && propsType === "polotno" && isTypeOf<PolotnoDesigner.PolotnoBadgePropsData>(props))
        return true;
    // If all checks failed, return false
    return false;
};

export default checkPropsType;
