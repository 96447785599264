import { ReactElement } from "react";

import TextField from "@mui/material/TextField";

import Helper from "components/shared/Helper";

export default function InputField(props: ITextFieldProps): ReactElement {
    const { name, value, onChange, onBlur, disabled, label, type, helperText, touched, errorText, error, ...rest } =
        props;
    return (
        <>
            <TextField
                id={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                disabled={disabled ?? false}
                error={error ?? false}
                type={type ?? "text"}
                label={label}
                helperText={helperText ?? false}
                {...rest}
            />
            {touched && errorText && errorText.length > 1 ? <Helper variant="warning">{errorText}</Helper> : null}
        </>
    );
}
