/**
 * This function capitalizes the first char of a string
 * @param string
 * @returns {string} The capitalized string
 * @example capitalizeFirstLetter('foo bar'); // Foo bar
 */
export function capitalizeFirstLetter(string: string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

/**
 * This function hides the email address and show only the first and last character using regex with the same length of the email address and replace the rest with *
 * @param email
 * @returns {string} The hidden email address
 * @example hideEmail('test@gmail.com' ); // t**t@gmail.com
 */
export function hideEmail(email: string | undefined): string {
    if (!email) return "";

    const atIndex = email.indexOf("@");
    if (atIndex === -1 || atIndex === 0) return "";

    const startPart = email.substring(0, atIndex);
    const endPart = email.substring(atIndex, email.length);

    let hiddenPart = "*";

    if (startPart.length > 2) {
        const middleStringHidden = "*".repeat(startPart.length - 2);
        hiddenPart = `${startPart[0]}${middleStringHidden}${startPart.slice(-1)}`;
    } else {
        hiddenPart = "*".repeat(startPart.length);
    }

    return `${hiddenPart}${endPart}`;
}
