export const dataUsage = [
    `FutureNext GmbH is not commercializing any kind of data gathered in our badge service.`,

    `Name, email address, access tokens for the app, auto-created certificate ids, LinkedIn id, and
                        statistics, such as the date of access or the social media shared on will be stored on the
                        FutureNext’s servers, exclusively in order to provide the user with the promised service of
                        creating unique personalized badges and to fulfill our client’s service of creating badge /
                        certificate campaigns.`,

    `Once the badge campaign is over and the Virtual Badge service is brought offline, your personal
                        data is kept for the pure and exclusive purpose of internal statistical analysis for the
                        improvement of the provided services, and will not be used for unauthorized marketing
                        communication according to the european General Data Protection Regulation (GDPR).`,

    ` If you wish that your personal data is deleted, please reach out to: hello@futurenext.de.`,
    `Once the Virtual Badge service ends, data is aggregated for reporting purposes.`,
    `For FutureNext’s data privacy agreement see https://futurenext.de/privacy/`,
];

export const IntelectualProperty = [
    `Systematically retrieve data or other content from the Site to create or compile, directly or
                        indirectly, a collection, compilation, database, or directory without written permission from
                        us.`,

    `Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive
                        account information such as user passwords.`,

    `Circumvent, disable, or otherwise interfere with security-related features of the Site,
                        including features that prevent or restrict the use or copying of any Content or enforce
                        limitations on the use of the Site and/or the Content contained therein.`,

    `Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.`,

    `Use any information obtained from the Site in order to harass, abuse, or harm another person.`,

    `Make improper use of our support services or submit false reports of abuse or misconduct.`,

    `Use the Site in a manner inconsistent with any applicable laws or regulations.`,

    `Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other
                        material, including excessive use of capital letters and spamming (continuous posting of
                        repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Site
                        or modifies, impairs, disrupts, alters, or interferes with the use, features, functions,
                        operation, or maintenance of the Site.`,

    `Engage in any automated use of the system, such as using scripts to send comments or messages,
                        or using any data mining, robots, or similar data gathering and extraction tools.`,

    `Delete the copyright or other proprietary rights notice from any Content`,

    `Attempt to impersonate another user or person or use the username of another user.`,

    `Sell or otherwise transfer your profile.`,

    ` Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or
                        active information collection or transmission mechanism, including without limitation, clear
                        graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices
                        (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).`,

    `Interfere with, disrupt, or create an undue burden on the Site or the networks or services
                        connected to the Site.`,
    `Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any
                        portion of the Site to you.`,

    `Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site,
                        or any portion of the Site.`,

    `Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or
                    other code.`,

    `Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way
                    making up a part of the Site.`,

    `Except as may be the result of standard search engine or Internet browser usage, use, launch,
                    develop, or distribute any automated system, including without limitation, any spider, robot, cheat
                    utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized
                    script or other software.`,

    `Use a buying agent or purchasing agent to make purchases on the Site.`,

    `Make any unauthorized use of the Site, including collecting usernames and/or email addresses of
                    users by electronic or other means for the purpose of sending unsolicited email, or creating user
                    accounts by automated means or under false pretenses.`,
    `Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content
                    for any revenue-generating endeavor or commercial enterprise.`,
];

export const userGeneratedContributions = [
    `The creation, distribution, transmission, public display, or performance, and the accessing,
                    downloading, or copying of your Contributions do not and will not infringe the proprietary rights,
                    including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any
                    third party.`,

    `You are the creator and owner of or have the necessary licenses, rights, consents, releases, and
                    permissions to use and to authorize us, the Site, and other users of the Site to use your
                    Contributions in any manner contemplated by the Site and these Terms of Use.`,

    `You have the written consent, release, and/or permission of each and every identifiable individual
                    person in your Contributions to use the name or likeness of each and every such identifiable
                    individual person to enable inclusion and use of your Contributions in any manner contemplated by
                    the Site and these Terms of Use.`,

    `Your Contributions are not false, inaccurate, or misleading.`,

    `Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid
                    schemes, chain letters, spam, mass mailings, or other forms of solicitation.`,

    `Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous,
                    slanderous, or otherwise objectionable (as determined by us).
                `,
    `Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.`,

    `Your Contributions do not advocate the violent overthrow of any government or incite, encourage, or
                    threaten physical harm against another.`,

    `Your Contributions do not violate any applicable law, regulation, or rule.`,
    `Your Contributions do not violate the privacy or publicity rights of any third party.`,

    `Your Contributions do not contain any material that solicits personal information from anyone under
                    the age of 18 or exploits people under the age of 18 in a sexual or violent manner.`,

    `Your Contributions do not violate any applicable law concerning child pornography, or otherwise
                    intended to protect the health or well-being of minors;`,

    ` Your Contributions do not include any offensive comments that are connected to race, national
                    origin, gender, sexual preference, or physical handicap.
                `,

    `Your Contributions do not otherwise violate, or link to material that violates, any provision of
                    these Terms of Use, or any applicable law or regulation.`,
];
