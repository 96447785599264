import { socialMediaColors, socialMediaHoverColors, theme } from "utils/Theme/theme";

export const emailButton = {
    backgroundColor: socialMediaColors.email,
    color: theme.palette.text.primary,
    width: "100% !important",
    height: "100%",
    ":hover": {
        backgroundColor: socialMediaHoverColors.email,
    },
};
