import type { FC } from "react";

import InstagramIcon from "@mui/icons-material/Instagram";
import { Box } from "@mui/material";

import Button from "components/shared/Button";

import * as styles from "./AddInstagramButton.styles";

const AddInstagramButton: FC<IButtonProps> = ({ ...props }) => {
    return (
        <Box>
            <Button
                variant="contained"
                startIcon={<InstagramIcon sx={{ height: 24, width: 24 }} />}
                sx={{
                    ...styles.instagramButton,
                    "& .MuiButton-startIcon": {
                        marginRight: 0,
                    },
                    minWidth: "fit-content",
                    height: "100%",
                }}
                {...props}
            />
        </Box>
    );
};

export default AddInstagramButton;
