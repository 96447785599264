import * as apple_ar from "./assets/apple/AR_Add_to_Apple_Wallet_RGB_101421.svg";
import * as apple_de from "./assets/apple/DE_Add_to_Apple_Wallet_RGB_101421.svg";
import * as apple_es from "./assets/apple/ES_Add_to_Apple_Wallet_RGB_101921.svg";
import * as apple_fr from "./assets/apple/FR_Add_to_Apple_Wallet_RGB_102921.svg";
import * as apple_nl from "./assets/apple/NL_Add_to_Apple_Wallet_RGB_101921.svg";
import * as apple_pt from "./assets/apple/PT_Add_to_Apple_Wallet_RGB_102021.svg";
import * as apple_ptbr from "./assets/apple/PTBR_Add_to_Apple_Wallet_RGB_102121.svg";
import * as apple_usuk from "./assets/apple/US-UK_Add_to_Apple_Wallet_RGB_101421.svg";
import * as google_ar from "./assets/google/ar_add_to_google_wallet_add-wallet-badge.svg";
import * as google_ptbr from "./assets/google/br_add_to_google_wallet_add-wallet-badge.svg";
import * as google_de from "./assets/google/de_add_to_google_wallet_add-wallet-badge.svg";
import * as google_usuk from "./assets/google/enUS_add_to_google_wallet_add-wallet-badge.svg";
import * as google_es from "./assets/google/esES_add_to_google_wallet_add-wallet-badge.svg";
import * as google_fr from "./assets/google/frFR_add_to_google_wallet_add-wallet-badge.svg";
import * as google_nl from "./assets/google/nl_add_to_google_wallet_add-wallet-badge.svg";
import * as google_pt from "./assets/google/pt_add_to_google_wallet_add-wallet-badge.svg";
import type { TWalletButtonObject } from "./walletButton.types";

export const languages = ["en", "fr", "de", "pt-BR", "pt", "nl", "es", "ar"] as const;

const walletButtons: TWalletButtonObject = {
    apple: {
        en: apple_usuk.default,
        "en-US": apple_usuk.default,
        de: apple_de.default,
        fr: apple_fr.default,
        nl: apple_nl.default,
        es: apple_es.default,
        pt: apple_pt.default,
        "pt-BR": apple_ptbr.default,
        ar: apple_ar.default,
    },
    google: {
        en: google_usuk.default,
        "en-US": google_usuk.default,
        de: google_de.default,
        fr: google_fr.default,
        nl: google_nl.default,
        es: google_es.default,
        pt: google_pt.default,
        "pt-BR": google_ptbr.default,
        ar: google_ar.default,
    },
};

export default walletButtons;
