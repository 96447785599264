interface NavigatorWithConnection extends Navigator {
    connection?: {
        effectiveType?: string;
    };
}

declare const navigator: NavigatorWithConnection;

const detectDeviceType = (): string => {
    const userAgent = navigator.userAgent;

    // Check for tablet user agents
    if (/Tablet|iPad/i.test(userAgent)) {
        return "Tablet";
    }

    // Check for mobile user agents
    if (/Mobi|Android/i.test(userAgent)) {
        return "Mobile";
    }

    // Default to desktop if no tablet or mobile user agents are detected
    return "Desktop";
};

/**
 * Collects environment information from the browser. This information can be used to debug issues.
 *
 * IMPORTANT: Some of navigator properties are deprecated and may not work in all browsers. However, they are still widely used and we don't have a better alternative.
 *
 * @returns {Record<string, unknown>} - Environment information
 */
export const collectEnvironmentInfo = (): Record<string, unknown> => {
    const detectStylus = (): boolean => {
        // Check for Stylus-specific elements.
        const stylusElements = document.querySelectorAll("style.stylus");
        // Check for Stylus-specific attribute.
        const stylusAttribute = document.querySelector("[stylus-1000]");
        return stylusElements.length > 0 || stylusAttribute !== null;
    };

    const extensions = {
        "google-translate": !!document.getElementById("goog-gt-tt"),
        grammarly: !!document.querySelector(
            "grammarly-desktop-integration, grammarly-citation-builder, grammarly-mirror, grammarly-popups"
        ),
        deepL: !!document.querySelector("deepl-input-controller"),
        stylus: detectStylus(),
    };

    const environmentInfo = {
        browser: navigator.userAgent,
        extensions,
        os: navigator.platform ?? "unknown",
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        viewportSize: `${window.innerWidth}x${window.innerHeight}`,
        language: navigator.language,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        cookiesEnabled: navigator.cookieEnabled,
        referrer: document.referrer,
        networkType: navigator?.connection?.effectiveType ?? "unknown",
        deviceType: detectDeviceType(),
    };

    return environmentInfo;
};
