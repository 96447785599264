import { theme } from "utils/Theme/theme";

import { styled, typographyClasses } from "@mui/material";

const FooterLink = styled("div")(() => ({
    color: theme.palette.grey[500],
    marginTop: theme.spacing(1),
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(0.4),
        [`& .${typographyClasses.root}`]: {
            marginBottom: "0.5rem",
        },
    },
}));

export default FooterLink;
