import React from "react";

import { Box } from "@mui/material";

import Typography from "components/shared/Typography";

import * as styles from "./TabPanel.styles";

interface Props {
    children: React.ReactNode;
    index: number | string;
    value: number | string;
}

export default function TabPanel(props: Props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={styles.wrapper}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component="span" style={styles.textContent}>
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    );
}
