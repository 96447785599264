import { useEffect } from "react";

import HandleNavigation from "./components/organisms/HandleNavigation/HandleNavigation";
import {
    StyledBottomMessageText,
    StyledMessageText,
    StyledNotFoundContainer,
    StyledTopicText,
} from "./StyledNotFound/StyledNotFoundContainer";
import { useHandleNotFound } from "./notFound.hooks";

function NotFound({ checkForParams, enableNavBar = false }: { checkForParams: boolean; enableNavBar?: boolean }) {
    const { handleCheckParams, paramsFound, notFoundText, memorizedOptions, haveLogo } = useHandleNotFound();
    useEffect(() => {
        if (checkForParams && paramsFound) handleCheckParams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HandleNavigation enableNavBar={enableNavBar} branding={haveLogo ? memorizedOptions : null}>
            <StyledNotFoundContainer>
                <StyledTopicText>{notFoundText.topic}</StyledTopicText>
                <StyledMessageText>{notFoundText.message}</StyledMessageText>
                {notFoundText.bottomMessage && (
                    <StyledBottomMessageText>{notFoundText.bottomMessage}</StyledBottomMessageText>
                )}
            </StyledNotFoundContainer>
        </HandleNavigation>
    );
}

export default NotFound;
