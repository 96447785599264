import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import redirectionSelectorService from "../../../redux/selector/redirections";
import { useAppSelector } from "../../../redux/store";

/**
 * Hook to handle the redirection from redux store
 *
 * This hook is not to use seperate. It is only placed inside the RedirectProvider component to apply redux-redirection.
 *
 * Everytime the store for redirection changed it hits the redirection.
 *
 * @var {boolean} applyRedirect Indication if the redirection should be applied or not.
 * @var {TRedirectionState | null} redirection The redirection information object from the redux-selector.
 * @var {TRedirectState | null} appliedRedirection The redirection information object memorized fromm redirection.
 * @function doRedirection Memorized callback function to handle redirection.
 */
const useRedirection = () => {
    const navigate = useNavigate();

    const [applyRedirect, setApplyRedirect] = useState<boolean>(false);

    const redirect = useAppSelector(redirectionSelectorService.selectRedirection);

    const appliedRedirection = useMemo(() => {
        setApplyRedirect(() => true);
        return redirect;
    }, [redirect]);

    const doRedirection = useCallback(
        (redirectObject: typeof appliedRedirection) => {
            if (applyRedirect && redirectObject) navigate(redirectObject.to, redirectObject.options);
            setApplyRedirect(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [applyRedirect]
    );

    useEffect(() => {
        doRedirection(appliedRedirection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedRedirection]);
};

export default useRedirection;
