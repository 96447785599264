import { axios as api } from "lib/axios";

import usePushSnack from "hooks/SnackbarManager/usePushSnack";
import useTranslation from "i18n/hooks/useTranslation";

import certificatesActionService from "../../../redux/actions/certificates";
import { useShareToSocialMedia } from "../../../redux/hooks/socialMediaShare";
import { useGetOrgaSlugs } from "../../../redux/hooks/validation";
import certificatesSelectorService from "../../../redux/selector/certificates";
import controlSelectorService from "../../../redux/selector/control";
import eventSelectorService from "../../../redux/selector/events";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { ESocialMediaPlatforms } from "../../../types/enums";
import { urlToBase64 } from "../../../utils/urlToBase64";

import { CertificateDownloaderApiRoutes } from "./routes";

export const useTwitterRequestToken = () => {
    const t = useTranslation();
    const pushSnack = usePushSnack();

    const { orga_slug } = useGetOrgaSlugs();

    const windowReference = window.open("", "_self");

    const requestTwitterToken = async () => {
        const body = {
            orga_slug,
        };
        try {
            const res = await api.post(CertificateDownloaderApiRoutes.getTwitterRequestToken, body);
            if (windowReference) {
                windowReference.location = `${res.data.twitter_authorize_url}`;
            }
        } catch (err) {
            pushSnack({
                title: t("common.alerts.messages.unexpected_error_title"),
                body: (err as { message: string }).message,
                type: "error",
            });
        }
    };
    return requestTwitterToken;
};

export const useShareLinkedinCall = () => {
    const t = useTranslation();
    const pushSnack = usePushSnack();
    const dispatch = useAppDispatch();

    const { setSuccessfull, setError, getShareLoadingState, setPending } = useShareToSocialMedia();

    const loadingState = () => getShareLoadingState(ESocialMediaPlatforms.LINKEDIN);
    const setPendingState = () => setPending(ESocialMediaPlatforms.LINKEDIN);
    const setErrorState = () => setError(ESocialMediaPlatforms.LINKEDIN);

    const { certificate_id } = useAppSelector(certificatesSelectorService.selectedCertificate);
    const token = useAppSelector(controlSelectorService.selectToken);

    const linkedin_access_token = localStorage.getItem("linkedin_access_token");

    const shareLinkedin = async (text: string) => {
        const body = {
            cert_id: certificate_id,
            text,
            linkedin_access_token,
            token,
        };
        const data = api
            .post(CertificateDownloaderApiRoutes.shareLinkedin, body)
            .then(() => {
                if (certificate_id !== undefined) localStorage.setItem("last_viewed_certificate", certificate_id);
                pushSnack({
                    title: t("certificate_download.alerts.status.success"),
                    body: t("certificate_download.alerts.success_linkedin"),
                    type: "success",
                    autoHideDuration: 10000,
                });
                dispatch(
                    certificatesActionService.postStats({
                        certificate_id,
                        stats_type: "stats_share_linkedin_count",
                    })
                );
                setSuccessfull(ESocialMediaPlatforms.LINKEDIN);
            })
            .catch((err) => {
                setError(ESocialMediaPlatforms.LINKEDIN);
                pushSnack({
                    title: t("common.alerts.messages.unexpected_error_title"),
                    body: err.message,
                    type: "error",
                });
            });
        return data;
    };
    return { shareLinkedin, loadingState, setPendingState, setErrorState };
};

export const useShareTwitterCall = () => {
    const t = useTranslation();
    const pushSnack = usePushSnack();
    const dispatch = useAppDispatch();

    const { setSuccessfull, setError, getShareLoadingState, setPending } = useShareToSocialMedia();

    const loadingState = () => getShareLoadingState(ESocialMediaPlatforms.TWITTER);
    const setPendingState = () => setPending(ESocialMediaPlatforms.TWITTER);
    const setErrorState = () => setError(ESocialMediaPlatforms.TWITTER);

    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);
    const { badge_image_url: certificateExportedImg, certificate_id } = useAppSelector(
        certificatesSelectorService.selectedCertificate
    );

    const { orga_slug } = useGetOrgaSlugs();

    const shareTwitter = async (text: string) => {
        const body = {
            oauth_token: localStorage.getItem("twitter_oauth_token"),
            oauth_token_secret: localStorage.getItem("twitter_oauth_token_secret"),
            badge: await urlToBase64(certificateExportedImg as string),
            text,
            certificate_id,
            container_name: eventDetails?.event_container_name,
            orga_slug,
        };
        try {
            const data = await api.post(CertificateDownloaderApiRoutes.shareTwitter, body);
            if (certificate_id !== undefined) localStorage.setItem("last_viewed_certificate", certificate_id);
            pushSnack({
                title: t("certificate_download.alerts.status.success"),
                body: t("certificate_download.alerts.success_twitter"),
                type: "success",
                autoHideDuration: 10000,
            });
            dispatch(
                certificatesActionService.postStats({
                    certificate_id,
                    stats_type: "stats_share_twitter_count",
                })
            );
            setSuccessfull(ESocialMediaPlatforms.TWITTER);
            return data;
        } catch (error: any) {
            pushSnack({
                title: t("common.alerts.messages.unexpected_error_title"),
                body: error.message,
                type: "error",
            });
            setError(ESocialMediaPlatforms.TWITTER);
            return error;
        }
    };
    return { shareTwitter, loadingState, setPendingState, setErrorState };
};
