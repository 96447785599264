import { useCallback } from "react";

import eventSelectorService from "../redux/selector/events";
import { useAppSelector } from "../redux/store";
import { EVariantTranslation } from "../types/enums";

import { getTranslatedCertificateOrBadge } from "./misc";

type TEventVariants = "lowercase_singular" | "lowercase_plural" | "uppercase_singular" | "uppercase_plural";

const useGetTranslatedEventType = () => {
    const currentEvent = useAppSelector(eventSelectorService.selectFetchedEvent);

    const translatedEventType = useCallback(
        (type: TEventVariants) => {
            const requestedType: Record<TEventVariants, EVariantTranslation> = {
                lowercase_singular: EVariantTranslation.LOWERCASE_SINGULAR,
                lowercase_plural: EVariantTranslation.LOWERCASE_PLURAL,
                uppercase_singular: EVariantTranslation.UPPERCASE_SINGULAR,
                uppercase_plural: EVariantTranslation.UPPERCAE_PLURAL,
            };

            const eventType = getTranslatedCertificateOrBadge(currentEvent?.event_type, requestedType[type]);

            return eventType;
        },
        [currentEvent?.event_type]
    );

    return { translatedEventType };
};

export default useGetTranslatedEventType;
