import React from "react";
import { useField } from "formik";

import TextField from "./TextField";

export const InputFieldContainer: React.FC<InputFieldContainerProps> = ({ name, value, label, ...rest }) => {
    const [field, meta] = useField(name);
    return (
        <TextField
            name={name}
            value={value || (!field.value ? "" : field.value)}
            label={label}
            onChange={field.onChange}
            touched={meta.touched}
            errorText={meta.error}
            {...rest}
        />
    );
};

export default InputFieldContainer;
