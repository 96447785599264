export enum EAuthButton {
    EMAIL = "email",
    SOCIAL_MEDIA = "social_media",
}

export enum ESocialMediaPlatforms {
    TWITTER = "twitter",
    FACEBOOK = "facebook",
    LINKEDIN = "linkedin",
    INSTAGRAM = "instagram",
    WHATSAPP = "whatsapp",
}

export enum EAuthType {
    LINKEDIN = "linkedin",
    FACEBOOK = "facebook",
    TWITTER = "twitter",
    EMAIL = "email",
}

export enum EBadgeType {
    BADGE = "badge",
    POLOTNO = "polotno",
}

export enum ESeverity {
    SUCCESS = "success",
    ERROR = "error",
}

export enum EBadgeFormat {
    A4_LANDSCAPE = "A4_LANDSCAPE",
    A4_PORTRAIT = "A4_PORTRAIT",
    US_LETTER_LANDSCAPE = "US_LETTER_LANDSCAPE",
    US_LETTER_PORTRAIT = "US_LETTER_PORTRAIT",
    LEGACY_BADGE_3_TO_4 = "LEGACY_BADGE_3_TO_4",
    BADGE_1_TO_1 = "BADGE_1_TO_1",
}

export enum EEventStatus {
    DRAFT = "draft",
    ACTIVE = "active",
    PAUSED = "paused",
    COMPLETED = "completed",
}

export enum EEventType {
    GENERIC = "generic",
    BADGE = "badge",
    CERTIFICATE = "certificate",
    EVENT = "event",
}

export enum ECertificateShareButtons {
    LinkedinShare = "linkedin-share",
    FacebookShare = "facebook-share",
    TwitterShare = "twitter-share",
    LinkedinCertificate = "linkedin-certificate",
    InstagramShare = "instagram-share",
    WhatsappShare = "whatsapp-share",
}

export enum EAccountType {
    vb = "virtualbadge",
}

export type TAccountType = EAccountType;

export enum EVariantTranslation {
    LOWERCASE_SINGULAR = "lowercase_singular",
    LOWERCASE_PLURAL = "lowercase_plural",
    UPPERCASE_SINGULAR = "uppercase_singular",
    UPPERCAE_PLURAL = "uppercase_plural",
}

export enum EEventPdfType {
    BROWSER = "browser_pdf",
    BACKEND = "backend_pdf",
}
