// TODO: Remove the entire component and the translation from the `locize` after LinkedIn bug is fixed. Task: #13159

import type { FC, MouseEvent } from "react";
import { useState } from "react";

import useTranslation from "i18n/hooks/useTranslation";

import InfoIcon from "@mui/icons-material/Info";
import { Box, IconButton, Popover } from "@mui/material";

const DisabledInIOSInformation: FC = () => {
    const t = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? "disabled-in-iOS-popover" : undefined;

    return (
        <Box display="inline-flex">
            <IconButton aria-describedby={id} onClick={handleClick} style={{ alignItems: "flex-start" }}>
                <InfoIcon style={{ fontSize: "16px" }} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClick}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Box p={2}>{t("certificate_download.share_certificate.share_certificate_disabled_ios_popover")}</Box>
            </Popover>
        </Box>
    );
};

export default DisabledInIOSInformation;
