/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useNavigate } from "react-router-dom";

import { useBrand } from "hooks/BrandManager/BrandManager.hooks";
import useTranslation from "i18n/hooks/useTranslation";
import { routes } from "utils/routes";
import { theme } from "utils/Theme/theme";

import { Box, useMediaQuery } from "@mui/material";

import VbLogo from "assets/images/logos/vb-logo-dark.svg";
import Typography from "components/shared/Typography";

import eventSelectorService from "../../redux/selector/events";
import { useAppSelector } from "../../redux/store";

import FooterAnchor from "./components/atoms/FooterAnchor/FooterAnchor";
import FooterContainer from "./components/atoms/FooterContainer/FooterContainer";
import FooterDivider from "./components/atoms/FooterDivider/FooterDivider";
import FooterLink from "./components/atoms/FooterLink/FooterLink";
import * as styles from "./Footer.styles";

const Footer: React.FC<IFooterProps> = ({ withLogo = true }) => {
    const navigate = useNavigate();
    const t = useTranslation();
    const brandManager = useBrand();
    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);

    const isMaxSm = useMediaQuery(() => theme.breakpoints.down("sm"));

    return (
        <FooterContainer>
            {isMaxSm && <FooterDivider />}
            <FooterLink onClick={() => navigate(routes.Terms)}>
                <Typography variant="body2">{t("common.footer.terms")}</Typography>
            </FooterLink>
            <FooterLink onClick={() => navigate(routes.Privacy)}>
                <Typography variant="body2">{t("common.footer.privacy")} </Typography>
            </FooterLink>
            <FooterLink onClick={() => navigate(routes.Imprint)}>
                <Typography variant="body2"> {t("common.footer.imprint")} </Typography>
            </FooterLink>
            {eventDetails?.event_contact_email && (
                <FooterAnchor href={`mailto:${eventDetails?.event_contact_email}`}>
                    <Typography variant="body2"> {t("common.footer.contact")} </Typography>
                </FooterAnchor>
            )}
            {withLogo && (
                <Box sx={{ ...styles.footerContainer, ...styles.footerLogoContainer }}>
                    <span style={styles.footerText}>powered by </span>
                    <a href={brandManager.getProductHomepage()}>
                        <img src={VbLogo} width="180" alt="Vb logo" />
                    </a>
                </Box>
            )}
        </FooterContainer>
    );
};

export default Footer;
