import { EAuthFormTokenHandling } from "./authForm.enums";

/**
 * Helper functions for handling OTP tokens and authentication tokens.
 */
export const otpTokenHandling = () => {
    /**
     * Sets the value of the specified token handling type in the local storage.
     * @param handle - The token handling type.
     * @param authToken - The authentication token value (optional).
     */
    const set = (handle: EAuthFormTokenHandling, authToken?: string) => {
        const currentTimestamp = Date.now().toString;
        switch (handle) {
            case EAuthFormTokenHandling.OTP_TOKEN_SENT:
                localStorage.setItem("otp_token_sent", `${currentTimestamp}`);
                break;
            case EAuthFormTokenHandling.LAST_USED_AUTH_TOKEN:
                if (authToken) localStorage.setItem("last_used_auth_token", authToken);
                break;
            case EAuthFormTokenHandling.BOTH:
                if (authToken) localStorage.setItem("last_used_auth_token", authToken);
                localStorage.setItem("otp_token_sent", `${currentTimestamp}`);
                break;
            default:
                break;
        }
    };

    /**
     * Retrieves the value of the specified token handling type from the local storage.
     * @param handle - The token handling type.
     * @returns The value of the token or null if not found.
     */
    const get = (handle: EAuthFormTokenHandling) => {
        switch (handle) {
            case EAuthFormTokenHandling.OTP_TOKEN_SENT:
                return localStorage.getItem("otp_token_sent");
            case EAuthFormTokenHandling.LAST_USED_AUTH_TOKEN:
                return localStorage.getItem("last_used_auth_token");
            default:
                return null;
        }
    };

    /**
     * Removes the specified token handling type from the local storage.
     * @param handle - The token handling type.
     */
    const remove = (handle: EAuthFormTokenHandling) => {
        switch (handle) {
            case EAuthFormTokenHandling.OTP_TOKEN_SENT:
                localStorage.removeItem("otp_token_sent");
                break;
            case EAuthFormTokenHandling.LAST_USED_AUTH_TOKEN:
                localStorage.removeItem("last_used_auth_token");
                break;
            case EAuthFormTokenHandling.BOTH:
                localStorage.removeItem("otp_token_sent");
                localStorage.removeItem("last_used_auth_token");
                break;
            default:
                break;
        }
    };

    /**
     * Checks if the provided authentication token matches the last used authentication token.
     * @param token - The token to check.
     * @returns True if the token matches the last used authentication token, false otherwise.
     */
    const checkToken = (token: string) => token === get(EAuthFormTokenHandling.LAST_USED_AUTH_TOKEN);

    /**
     * Checks if the last click on send otp token was longer than the specified number of minutes ago.
     * If there is no stored value in the local storage, it will return true.
     * @param minutes - The number of minutes to compare against.
     * @returns True if the last click was longer than the specified minutes ago, false otherwise.
     */
    const checkLastClickedLongerThan = (minutes: number) => {
        const otpTokenSent = get(EAuthFormTokenHandling.OTP_TOKEN_SENT);
        if (!otpTokenSent) return true;
        // If the token is older than x minutes, return true
        return Date.now() - parseInt(otpTokenSent, 10) > minutes * 60 * 1000;
    };

    return {
        set,
        get,
        remove,
        checkToken,
        checkLastClickedLongerThan,
    };
};
