import MuiCheckbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import Helper from "components/shared/Helper";

export default function CheckboxBase({ label, errorText, touched, className, ...rest }: ICheckboxProps) {
    return (
        <div>
            <FormControlLabel control={<MuiCheckbox color="primary" {...rest} />} label={label} />
            {touched && errorText && <Helper variant="warning">{errorText}</Helper>}
        </div>
    );
}
