import i18n from "i18next";
import LngDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";

const locizeOptions = {
    apiKey: process.env.LOCIZE_API_KEY,
    projectId: process.env.LOCIZE_PROJECT_ID,
    referenceLng: "en",
    /**
     * `latest` for development and `production` for production
     */
    version: ["local", "development", "staging"].includes(process.env.ENVIRONMENT || "local") ? "latest" : "production",
};

const languageDetector = new LngDetector(null, {
    convertDetectedLanguage(lng) {
        // As the detected language can be a region-specific variant, we need to normalize it to a generic language code.

        let detectedLanguage = lng || "en";

        if (lng.startsWith("pt")) {
            detectedLanguage = detectedLanguage === "pt-BR" ? "pt-BR" : "pt";
        } else {
            detectedLanguage = detectedLanguage.slice(0, 2);
        }

        return detectedLanguage;
    },
});

i18n.use(languageDetector)
    .use(Backend)
    .init({
        fallbackLng: "en",
        debug: false,
        supportedLngs: ["en", "de", "fr", "pt_br", "pt", "nl", "es", "ar"],
        load: "languageOnly",
        returnEmptyString: false,
        backend: locizeOptions,
    });

export default i18n;
