import Box from "@mui/material/Box";
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    const { value, ...rest } = props;
    const cleanedValue = Number.isNaN(value) ? 100 : value;
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress variant="determinate" value={cleanedValue} {...rest} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(cleanedValue)}%`}</Typography>
            </Box>
        </Box>
    );
}

export default LinearProgressWithLabel;
