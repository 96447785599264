import { I18nextProvider } from "react-i18next";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import i18nInstance from "i18n";
import { routes } from "utils/routes";
import { theme } from "utils/Theme/theme";

import { CssBaseline, ThemeProvider } from "@mui/material";

import CookiesBanner from "components/CookiesBanner";
import ErrorBoundary from "components/ErrorBoundary";
import SEO from "components/SEO";
import ValidationWrapper from "components/ValidationWrapper";
import AppLayout from "layouts/AppLayout/AppLayout";
import CertificateDownload from "pages/CertificateDownload";
import CertificateValidator from "pages/CertificateValidator";
import Imprint from "pages/Imprint";
import Privacy from "pages/Privacy";
import Terms from "pages/Terms";

import RedirectProvider from "./features/customProvider/RedirectProvider/RedirectProvider";
import LandingPage from "./pages/LandingPage";
import NotFound from "./pages/NotFound/NotFoundContainer";
import { store } from "./redux/store";

export function App() {
    return (
        <Routes>
            <Route
                path={routes.LandingPage}
                element={
                    <ValidationWrapper>
                        <LandingPage />
                    </ValidationWrapper>
                }
            />
            <Route element={<AppLayout />}>
                <Route path={routes.Terms} element={<Terms />} />
                <Route path={routes.Privacy} element={<Privacy />} />
                <Route path={routes.Imprint} element={<Imprint />} />
                <Route path={routes.CertificateValidator} element={<CertificateValidator />} />
                <Route path={routes.YourCertificate} element={<CertificateDownload />} />
                <Route path="*" element={<NotFound checkForParams={false} />} />
            </Route>
        </Routes>
    );
}

export function WrappedApp() {
    return (
        <ThemeProvider theme={theme}>
            <ErrorBoundary>
                <ReduxProvider store={store}>
                    <I18nextProvider i18n={i18nInstance}>
                        <Router>
                            <RedirectProvider>
                                <SEO />
                                <CssBaseline />
                                <App />
                                <CookiesBanner />
                            </RedirectProvider>
                        </Router>
                    </I18nextProvider>
                </ReduxProvider>
            </ErrorBoundary>
        </ThemeProvider>
    );
}
