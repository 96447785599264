import { socialMediaColors, socialMediaHoverColors, theme } from "utils/Theme/theme";

export const emailButton = {
    backgroundColor: socialMediaColors.email,
    color: theme.palette.text.primary,
    width: "100%",
    height: "100%",
    "&:hover": {
        backgroundColor: socialMediaHoverColors.email,
    },
};

export const emailCodeVerify = {
    ".MuiOutlinedInput-root": { padding: 0 },
    minWidth: 250,
};

export const textFieldInput = { maxHeight: 50 };

export const emailCodeInput = {
    marginLeft: 0,
};

export const resendCodeButton = {
    fontWeight: 700,
    width: "100% !important",
    maxHeight: 50,
};

export const linkToSocialMediaButton = {
    width: "fit-content",
    // eslint-disable-next-line @typescript-eslint/no-shadow
    color: (theme: IMuiTheme) => theme.palette.primary.dark,
    fontSize: 16,
    fontWeight: 400,
};

export const codeSubmitButton = {
    borderRadius: "0 4px 4px 0",
};
