/**
 * Fetches the file from the given URL and returns it as a base64 encoded string.
 * Allows choosing between a "pure" (with data prefix) and "clean" (without data prefix) format.
 *
 * @param {string} url The URL of the file to fetch.
 * @param {"pure" | "clean"} format Desired format of the base64 string. Defaults to "pure".
 * @throws {Error} Throws an error if there is a failure in fetching or processing the image.
 * @returns {Promise<string>} Base64 encoded string in the desired format.
 */
export const urlToBase64 = async (url: string, format: "pure" | "clean" = "pure"): Promise<string> => {
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`Failed to download image. Status: ${response.status}`);
    }

    const blob = await response.blob();
    const base64Image = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = () => reject(new Error("Failed to read the image blob."));
        reader.readAsDataURL(blob);
    });

    return format === "clean" ? base64Image.replace(/^data:image\/\w+;base64,/, "") : base64Image;
};
