import { Divider } from "@mui/material";

import Typography from "components/shared/Typography";

import * as styles from "./ImprintContent.styles";

const ImprintContent = () => {
    return (
        <>
            <Typography variant="h1">Imprint</Typography>
            <Typography sx={styles.italicText}>English version:</Typography>
            <Typography>Virtualbadge.io is provided by FutureNext GmbH.</Typography>

            <Typography>Responsible:</Typography>
            <Typography>
                FutureNext GmbH
                <br />
                Mafinex Technologiezentrum
                <br />
                Julius-Hatry-Str. 1<br />
                68163 Mannheim
                <br />
                Germany
            </Typography>
            <Typography>
                <a href="https://www.virtualbadge.io/">www.virtualbadge.io</a>
                <br />
                <a href="https://www.futurenext.de/">www.futurenext.de</a>
            </Typography>
            <Typography>
                Commercial Register No.: HRB 736146
                <br />
                Commercial Court: Handelsregister Mannheim
            </Typography>
            <Typography>
                Tel.: +49 621 43049284 <br />
                E-Mail: hello@futurenext.de
            </Typography>
            <Typography>Directors: Giovanna Pergher, Kenny Strubel, Daniel Szymkowiak, Malte Zander</Typography>
            <Typography>
                For further information on how we treat your data, please refer to the privacy policy (link in the
                Footer). <br />
                For the terms of use please refer to the terms of use (link in the Footer)
            </Typography>

            <Divider sx={styles.dividerSpacing} />
            <Typography sx={styles.italicText}>Deutsche Version:</Typography>

            <Typography>
                <Typography variant="h5">Angaben gemäß § 5 Telemediengesetz (TMG)</Typography>
                <br />
                FutureNext GmbH
                <br />
                Mafinex Technologiezentrum
                <br />
                Julius-Hatry-Str. 1<br />
                68163 Mannheim
                <br />
                Deutschland
            </Typography>
            <Typography>
                <Typography variant="h5">Vertreten durch</Typography>
                <br />
                FutureNext GmbH , diese vertreten durch die Geschäftsführer Giovanna Pergher, Kenny Strubel, Daniel
                Szymkowiak, Malte Zander.
            </Typography>
            <Typography>
                <Typography variant="h5">Sitz der Gesellschaft</Typography>
                <br />
                Mannheim, Deutschland <br />
                Handelsregister Mannheim, HRB 736146
            </Typography>
            <Typography>
                <Typography variant="h5">Umsatzsteuer-Identifikationsnummer</Typography>
                <br />
                DE 330 129 301
            </Typography>
            <Typography>
                <Typography variant="h5">Inhaltlich Verantwortlicher für Beiträge gem. § 55 Abs. 2 RStV:</Typography>
                <br />
                Daniel Szymkowiak
                <br />
                FutureNext GmbH
                <br />
                Mafinex Technologiezentrum
                <br />
                Julius-Hatry-Str. 1<br />
                68163 Mannheim
                <br />
                Deutschland
            </Typography>

            <Typography>
                <Typography variant="h5">Haftungsausschluss:</Typography>
                <br />
                Die FutureNext GmbH stellt die Inhalte dieser Internetseiten mit großer Sorgfalt zusammen und sorgt für
                deren regelmäßige Aktualisierung. Die FutureNext GmbH übernimmt keine Gewähr für die Aktualität,
                Richtigkeit und Vollständigkeit der Informationen auf diesen Seiten oder den jederzeitigen
                störungsfreien Zugang. Bei Verweisen (Links) auf Internetseiten Dritter übernimmt die FutureNext GmbH
                keine Verantwortung für die Inhalte der verlinkten Seiten.
            </Typography>
            <Typography>
                Für die Angebote Dritter können abweichende Regelungen gelten, insbesondere hinsichtlich des
                Datenschutzes. Weiterhin schließt die FutureNext GmbH ihre Haftung bei Serviceleistungen, insbesondere
                beim Download von durch die FutureNext GmbH zur Verfügung gestellten Dateien auf den Internetseiten der
                FutureNext GmbH für leicht fahrlässige Pflichtverletzungen aus.
            </Typography>

            <Typography>
                <Typography variant="h5">Schlichtung:</Typography> <br />
                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter
                http://ec.europa.eu/consumers/odr/ finden. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle sind wir nicht verpflichtet und grundsätzlich nicht bereit.
            </Typography>

            <Typography>
                <Typography variant="h5">Urheberrechte FutureNext GmbH , Mannheim, Deutschland:</Typography>
                <br />
                Alle Rechte vorbehalten. Text, Bilder, Grafiken, Ton, Animationen und Videos sowie deren Anordnung auf
                der Würth Website unterliegen dem Schutz des Urheberrechts und anderer Schutzgesetze. Der Inhalt dieser
                Website darf nicht zu kommerziellen Zwecken kopiert, verbreitet, verändert oder Dritten zugänglich
                gemacht werden. Einige Seiten enthalten außerdem Bilder, die dem Copyright Dritter unterliegen.
                Ausschließlich für den heruntergeladenen Inhalt der erstellen Badges besteht die ausdrückliche
                Genehmigung, dieses nicht-kommerziell zu teilen.
            </Typography>
            <Typography>
                Weitere Informationen darüber, wie wir Ihre Daten behandeln, finden Sie in der Datenschutzerklärung
                (Link in der Fusszeile). <br />
                Für die Nutzungsbedingungen verweisen wir auf die Nutzungsbedingungen (Link in der Fusszeile).
            </Typography>
        </>
    );
};

export default ImprintContent;
