import { cloneDeep } from "lodash";

/**
 * Adds timestamp parameter to a URL using URLSearchParams to prevent caching
 * @param {string} url - The original URL
 * @returns {string} URL with timestamp parameter
 */
export const addTimestampToUrl = (url: string): string => {
    try {
        const urlObject = new URL(url);
        const searchParams = new URLSearchParams(urlObject.search);
        searchParams.set("v", Date.now().toString());
        urlObject.search = searchParams.toString();
        return urlObject.toString();
    } catch (error) {
        // If the URL is relative or invalid, handle it differently.
        const separator = url.includes("?") ? "&" : "?";
        return `${url}${separator}v=${Date.now()}`;
    }
};

/**
 * Set the generated pages to the Polotno props and return the updated props.
 *
 * Additionally add a timestamp to the certificateBlobImageUrl to prevent caching issues.
 * As URLs are always the same, some browsers may cache the image and not update it.
 *
 * @param {PolotnoDesigner.PolotnoBadgePropsData} polotnoProps - The Polotno props.
 * @param {string} certificateBlobImageUrl - The certificate image URL.
 * @param {Array} certificatePages - The certificate pages.
 * @returns {PolotnoDesigner.PolotnoBadgePropsData} The updated Polotno props.
 */
export const setGeneratedPagesToPolotnoProps = (
    polotnoProps: PolotnoDesigner.PolotnoBadgePropsData,
    certificateBlobImageUrl?: string,
    certificatePages?: string[]
): PolotnoDesigner.PolotnoBadgePropsData => {
    // Return early if there is no certificateBlobImageUrl.
    if (!certificateBlobImageUrl) {
        return polotnoProps;
    }
    const clonedProps = cloneDeep(polotnoProps);

    // Handle the case when multiple pages are provided.
    if (certificatePages && certificatePages.length > 1) {
        certificatePages.forEach((pageUrl, index) => {
            if (clonedProps.pages[index]) {
                clonedProps.pages[index].background = addTimestampToUrl(pageUrl);
                clonedProps.pages[index].children = [];
            }
        });
    } else if (certificateBlobImageUrl) {
        clonedProps.pages[0].background = addTimestampToUrl(certificateBlobImageUrl);
        clonedProps.pages[0].children = [];
    }

    return clonedProps;
};
