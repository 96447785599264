/* eslint-disable react/no-unescaped-entities */
import { useEffect } from "react";

import { Box } from "@mui/material";

import HtmlList from "components/shared/HtmlList";
import Typography from "components/shared/Typography";

import {
    CollectionPurpose,
    COOKIES_ESSENTIAL,
    COOKIES_FUNCTIONAL,
    handleDeleteAndUpdateCookies,
    handleUpdateCookies,
    InformationChoosen,
    InformationCollected,
    OtherCollectionPurpose,
    PersonalInformation,
    SharingInformation,
    UseOfInformation,
} from "./PrivacyContent.helpers";
import * as styles from "./PrivacyContent.styles";

const PrivacyContent = () => {
    useEffect(() => {
        const timoutFct = setTimeout(() => {
            if (window.location.hash.includes("#cookies")) {
                const ele = document.getElementById("cookies");
                window.scrollTo(ele!.offsetLeft, ele!.offsetTop);
            }
        }, 500);
        return () => {
            clearTimeout(timoutFct);
        };
    }, []);

    return (
        <div>
            <Typography variant="h1">Privacy Policy</Typography>
            <Typography>The Virtual Badge is provided by FutureNext GmbH .</Typography>
            <Typography>
                FutureNext GmbH
                <br />
                Mafinex Technologiezentrum
                <br />
                Julius-Hatry-Str. 1<br />
                68163 Mannheim
                <br />
                Deutschland (Germany)
            </Typography>
            <Typography>
                <a href="https://www.virtualbadge.io/">www.virtualbadge.io</a>
                <br />
                <a href="https://www.futurenext.de/">www.futurenext.de</a>
            </Typography>

            <Typography variant="h2">LEGAL INFORMATION</Typography>
            <Typography>
                FutureNext GmbH (“us“ or “we“) respects the privacy of the parties visiting its Website, the home page
                of which is located at http://virtualbadge.io. The home page and accompanying pages will collectively be
                known as “Website“.
            </Typography>
            <Typography>
                This Privacy Policy is intended to educate you on our policies and practices regarding the collection,
                use, and disclosure of any Personal Information (defined below) and Anonymous Information (defined
                below) that you submit to us or that we collect through our Website. Unless otherwise defined herein,
                capitalized terms shall have the definitions assigned to such terms set forth in our Terms and
                Conditions that you can find at Terms and Conditions and which incorporate this Privacy Policy.
            </Typography>
            <Typography>
                “Personal Information“ is information about you that is personally identifiable to you, such as your
                name, address, email address, or phone number, as well as other non-public information that is
                associated with the foregoing. “Anonymous Information” is information that is not associated with or
                linked to your Personal Information; Anonymous Information does not allow the identification of
                individual persons. We collect and use Personal Information and Anonymous Information as indicated in
                this Privacy Policy.
            </Typography>

            <Typography variant="h2">USER CONSENT</Typography>
            <Typography>
                When you submit any Personal Information through our Website, you agree to the terms of this Privacy
                Policy and you expressly consent to the treatment of your Personal Information defined in this Privacy
                Policy.
            </Typography>

            <Typography variant="h2">INFORMATION COLLECTED</Typography>
            <Typography>
                We may collect Personal Information that you submit to us voluntarily through our Website or submitted
                to any of our personnel. We collect this information when you:
            </Typography>
            <HtmlList list={InformationCollected} type="ul" />
            <Typography>
                The types of information you can choose to provide on our site may, without limitation, include:
            </Typography>
            <HtmlList list={InformationChoosen} type="ul" />
            <Typography>
                Here is an expanded description of the types of personal information we may collect and how we may use
                it:
            </Typography>
            <HtmlList list={PersonalInformation} type="ul" />
            <Typography>
                We will only collect and use information that is necessary to comply with our legal obligations and
                assist us to administer our business and provide you with the services you request.
            </Typography>
            <Typography>
                Your Personal Information is protected. All the data and information that we receive is protected and
                only available to authorized personnel.
            </Typography>

            <Typography variant="h2">USE OF INFORMATION</Typography>
            <Typography>In short, we may use your Personal Information for the following general purposes:</Typography>
            <HtmlList list={UseOfInformation} type="ul" />
            <Typography>
                For example, we collect, analyze, and process your Personal Information as data controller for the
                following purposes:
            </Typography>
            <HtmlList list={CollectionPurpose} type="ul" />
            <Typography>
                The following are some other ways in which we may use your information to administer our business and
                provide you with the products and services you request or may be of interest to you:
            </Typography>
            <HtmlList list={OtherCollectionPurpose} type="ul" />
            <Typography>
                With regards to offering and measuring targeted advertisements and services, we may use Personal
                Information, subject to your prior express consent, to personalize your experience with our products and
                services, as well as to determine the effectiveness of our promotional campaigns. This will extend to
                third-party websites and applications.
            </Typography>
            <Typography>
                For any of the uses of your data described above that require your prior express consent, note that you
                may withdraw your consent by contacting us at badge@futurenext.de.
            </Typography>
            <Typography>
                We use Personal Information to help verify accounts and user activity, as well as to promote safety and
                security, such as by monitoring fraud and investigating suspicious or potentially illegal activity or
                violations of our terms or policies. Such processing is based on our legitimate interest in helping
                ensure the safety of our products and services.
            </Typography>
            <Typography variant="h5">Disclosure of Personal Information</Typography>
            <Typography>
                We make certain Personal Information available to strategic partners that work with us to provide our
                products and services or help us market to customers. Personal Information will only be shared by us
                with these companies in order to provide or improve our products, services, and advertising; it will not
                be shared with third parties for their own marketing purposes without your prior express consent, and
                will not be commercialized.
            </Typography>
            <Typography variant="h5">Information that will be Publicly Available</Typography>
            <Typography>
                Definitions: A badge is considered a certificate when at least one of the following occurs:
                <ol>
                    <li>the badge is shared to social media;</li>
                    <li>
                        badges are created from an event that has the “badge hosting” functionality enabled (compare{" "}
                        <a href="https://www.virtualbadge.io/price" target="_blank">
                            https://www.virtualbadge.io/price
                        </a>{" "}
                        for “badge hosting”).
                    </li>
                </ol>
            </Typography>
            <Typography>
                If you create a certificate either by clicking on the “Generate Certificate button“ or by sharing it to
                social media, your certificate will be stored on our servers. If the course admin, trainer, coach,
                academy, event host or whoseever badge you create, enables the badge hosting,i your badge will also be
                made publicly available on our badge validation page under:
                https://virtualbadge.io/certificate-validator. Everyone with the direct link to your certificate can
                view your certificate image such as information on the course you took. By creating the certificate or
                sharing it on social media, you agree that we may store and share your data in the manner described
                above.
            </Typography>
            <Typography variant="h5">Service Providers</Typography>
            <Typography>
                We share Personal Information with companies that provide services on our behalf, such as website
                hosting, email services, marketing, auditing, fulfilling customer requests, data analytics, providing
                customer service, and conducting customer research and satisfaction surveys. These companies are
                obligated to protect your information and may be located wherever we operate.
            </Typography>
            <Typography>
                The service used by FutureNext GmbH to support email sending operations is SparkPost, and their privacy
                policies for EU servers can be found at{" "}
                <a href="https://www.sparkpost.com/policies/privacy/" target="_blank">
                    https://www.sparkpost.com/policies/privacy/
                </a>
                .
            </Typography>
            <Typography variant="h5">Legal Compliance and Security</Typography>
            <Typography>
                It may be necessary by law, legal process, litigation, and/or requests from public and governmental
                authorities within or outside your country of residence for us to disclose Personal Information, as well
                as other information as needed. We may also disclose Personal Information if we determine that doing so
                is necessary or appropriate for the purposes of national security, law enforcement, or other issues of
                public importance. We may also disclose Personal Information if we determine in good faith that
                disclosure is reasonably necessary to protect our rights and pursue available remedies, enforce our
                terms and conditions, investigate fraud, or protect our operations or customers.
            </Typography>
            <Typography variant="h5">Ownership of Personal Information</Typography>
            <Typography>
                We will own and retain your Personal Information to fulfill the purposes outlined in this Privacy
                Policy, as well as similar purposes that may interest you, such as sending newsletters (once previously
                consented).
            </Typography>
            <Typography variant="h5">Legal Basis For The Processing of Personal Information</Typography>
            <Typography>
                If you are located in the European Economic Area (“EEA”), our processing of your Personal Information
                will be based on the following: To the extent that we obtain your consent for the processing of your
                Personal Data such processing will be justified pursuant to Article 6(1) lit. (a) of the General Data
                Protection Regulation (EU) 2016/679 (“GDPR”). If the processing of your Personal Information is
                necessary for the performance of a contract between you and us or for taking pre-contractual steps upon
                your request, such processing will be based on GDPR Article 6(1) lit. (b). Where the processing is
                necessary for us to comply with a legal obligation, we will process your Personal Information on the
                basis of GDPR Article 6(1) lit. (c), and where the processing is necessary for the purposes of our
                legitimate interests, such processing will be made in accordance with GDPR Article 6(1) lit. (f). Please
                note that where you have given your consent to the processing of your Personal Information you may
                withdraw your consent at any time which withdrawal will not affect the lawfulness of any processing
                previously made on basis of your consent.
            </Typography>
            <Typography variant="h5">Your Rights</Typography>
            <Typography>
                We take reasonable steps to ensure that your Personal Information is accurate, complete, and up to date.
                You have the right to access, correct, or delete the Personal Information that we collect. You are also
                entitled to restrict or object, at any time, to the further processing of your Personal Information. You
                have the right to receive your Personal Information in a structured and standard format. You may lodge a
                complaint with the competent data protection authority regarding the processing of your Personal
                Information. To protect the privacy and the security of your Personal Information, we may request
                information from you to enable us to confirm your identity and right to access such information, as well
                as to search for and provide you with the Personal Information we maintain. There are instances where
                applicable laws or regulatory requirements allow us to refuse to provide or delete some or all of the
                Personal Information that we maintain. In this case, we reserve the right to refuse to act on a request
                that is manifestly unfounded or excessive (for example, because it is repetitive) and/or to charge a fee
                that takes into account the administrative costs for providing the information or taking the action
                requested.
            </Typography>
            <Typography>
                In your request, please make clear what information you would like to access or have changed, whether
                you would like to have your Personal Information suppressed from our database, or other limitations you
                would like to put on our use of your Personal Information.
            </Typography>
            <Typography>
                We also use Anonymous Information, such as web or mobile pages you have viewed, in order to analyze
                request and usage patterns so that we may improve our products and services, enhance our user
                experience, and gather broad demographic information for aggregate use. Anonymous Information consists
                of data that would not identify you personally; these records exclude information (such as your name)
                that makes them personally identifiable to you. We may disclose Anonymous Information to third parties
                that promote our products and services. We reserve the right to use and disclose Anonymous Information
                to third parties in its discretion.
            </Typography>

            <Typography variant="h2">INFORMATION SHARING</Typography>
            <Typography>
                Except as otherwise stated in this Privacy Policy, we do not trade, commercialize, rent, or share your
                Personal Information with third-parties and we will not disclose financial information that is part of
                your Personal Information unless you request or authorize that we do so. If you provide Personal
                Information for a certain reason, we may use the Personal Information in connection with the reason for
                which it was provided. For instance, if you contact us by e-mail, we will use the Personal Information
                you provide to answer your question or resolve your problem and will respond to the email address from
                which the contact came.
            </Typography>
            <Typography>
                We may disclose Personal Information to its corporate subsidiaries or affiliated entities. Any Personal
                Information provided to our subsidiaries or affiliated entities will be treated by those subsidiaries
                and affiliated entities in accordance with the terms of this Privacy Policy.
            </Typography>
            <Typography>
                We may share your Personal Information with third-parties, to provide you with the products and services
                that we offer you through the Website. We make no representations or warranties as to how such parties
                will use your Personal Information. You expressly consent to the sharing of your Personal Information
                with our contractors and third-party service providers (for example, without limitation, individuals
                providing support services to us) for the sole purpose of providing products and services to you.
            </Typography>
            <Typography>
                We may participate in co-branding or co-promotional agreements with third parties pursuant to which we
                may share Personal Information with such third parties (“Partners“). For example, we may participate in
                agreements with Partners pursuant to which we provide the Partners with a URL and a customer
                registration page co-branded with or privately labeled by the Partners, and the Partners distribute and
                promote the URL to its customers. A Partner may have access to Personal Information that we collect from
                their customers. As a result, if you register on the Website through a Partner, we may provide your
                Personal Information to the Partner. We do not control the privacy practices of these Partners.
            </Typography>
            <Typography>
                We may offer you the chance to participate in drawings, contests, giveaways, and promotions (“Special
                Promotions“) through our Website. By registering for a Special Promotion, you agree to the official
                rules that govern that Special Promotion, which may contain specific requirements of you, including,
                except where prohibited by law, allowing the sponsor(s) of the Special Promotion to use your name, voice
                and/or likeness in advertising or marketing associated with the Special Promotion. If you choose to
                enter a drawing, contest, or other promotion, personally identifiable information may be disclosed to
                third parties or the public in connection with the administration of such Special Promotion, including,
                without limitation, in connection with winner selection, prize fulfillment, and as required by law or
                permitted by the Special Promotion’s official rules, such as on a winners list.
            </Typography>
            <Typography>
                We may use your Personal Information in order to provide to third parties non-personal aggregated
                information about you that does not allow you to be identified or contacted and that is combined with
                the Personal Information of other users (“Aggregate Information“). For example, we might inform third
                parties regarding the number of users of our site and the activities they conduct while on our site. We
                might also inform a company that performs services or that provides products and/or services to us (that
                may or may not be a business partner or an advertiser on our site) that “50% of our users live in the
                USA“ or that “85% of our users have downloaded their Virtual Badges from the Website”. Depending on the
                circumstances, we may or may not charge third parties for this Aggregate Information. We may not limit
                the third parties' use of the Aggregate Information.
            </Typography>

            <Typography>
                If another company acquires our company or our assets, that company will possess the Personal
                Information we have collected and that company will assume the rights and obligations regarding your
                Personal Information as indicated in this Privacy Policy.
            </Typography>
            <Typography>
                Under the following scenarios, we may be required to share your Personal Information:
            </Typography>

            <HtmlList list={SharingInformation} type="ul" />

            <Typography variant="h2">OPT-OUT</Typography>
            <Typography>
                You have options regarding the collection, use and sharing of your Personal Information. When you
                receive promotional communications from us, you may specify a preference to stop receiving further
                communications from us and you will have the chance to opt-out. Despite your selected preferences, we
                may send you communications regarding the services we provide to you, such as notices about
                administrative updates, transaction reports, notices of new Website releases, and notices regarding
                changes to the Website, updates to our Privacy Policy, Terms of Use, or other policies. You may make
                changes to any of the Personal Information in your account.
            </Typography>

            <Typography variant="h2">OTHER WEBSITES</Typography>
            <Typography>
                Our provision of a link to any other website or location is for your convenience and does not indicate
                our endorsement of such website or location or its contents. We have no control over, do not review, and
                cannot be responsible for these outside websites or their content. Please be aware that the terms of our
                Privacy Policy do not apply to these outside websites.
            </Typography>

            <Typography variant="h2" id="cookies">
                COOKIES AND SIMILAR TECHNOLOGIES
            </Typography>
            <Typography>
                We may use pixel tags, cookies, and/or other similar technologies, sometimes from third parties, to
                collect visitor information. Cookies, for example, are alphanumeric identifiers that we transfer to your
                computer's hard drive through your web browser. They make it possible for us to save the items in your
                shopping cart until you are ready to check out, recognize your browser when you visit and provide you
                with information about products that interest you. By doing this, we can customize your return visits
                and save you time during checkout. It is possible to deactivate cookies from being used in your browser
                by turning the feature off. According to the GDPR (DSGVO) legislation, you will be asked to state your
                cookie preference.
            </Typography>

            <Typography>
                <a href="#cookies" onClick={handleUpdateCookies}>
                    Update
                </a>{" "}
                your cookie preferences or{" "}
                <a href="#cookies" onClick={handleDeleteAndUpdateCookies}>
                    delete all cookies and update
                </a>{" "}
                your cookie preferences
            </Typography>

            <Typography variant="h2">Essentiel</Typography>
            <Typography>A full list of cookies is depicted below:</Typography>
            <Box sx={styles.coockieTable}>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Storage duration</th>
                            <th>Persistent / Session</th>
                            <th>Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        {COOKIES_ESSENTIAL.map((val) => {
                            return (
                                <tr key={val.name}>
                                    <td>{val.name}</td>
                                    <td>
                                        {val.description}
                                        {val.link && (
                                            <a target="_blank" href={val.link}>
                                                {val.link}
                                            </a>
                                        )}
                                    </td>
                                    <td>{val.storage_duration}</td>
                                    <td>{val.persistent_session}</td>
                                    <td>{val.category}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Box>

            <Typography>
                We further use the browser's local storage to store information about the user. This information falls
                under the category “Essential“. Local storage is persisted and not bound or limited to sessions. Other
                than cookies, local storage is not sent with every request. More information on local storage can be
                found here:{" "}
                <a target="_blank" href="https://www.w3schools.com/html/html5_webstorage.asp">
                    https://www.w3schools.com/html/html5_webstorage.asp
                </a>
            </Typography>
            <Box sx={styles.coockieTable}>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>i18nextLng</td>
                            <td>
                                Determines the language used. Is automatically detected and defaults to “en“ (English).
                                More information on how the language is automatically detected can be found here:{" "}
                                <a
                                    target="_blank"
                                    href="https://github.com/i18next/i18next-browser-languageDetector#detector-options"
                                >
                                    https://github.com/i18next/i18next-browser-languageDetector#detector-options
                                </a>
                                .
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Box>

            <Typography variant="h2">Functional</Typography>
            <Typography>
                All cookies from Essential plus the full list of additional cookies as depicted below:
            </Typography>
            <Box sx={styles.coockieTable}>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Storage duration</th>
                            <th>Persistent / Session</th>
                            <th>Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        {COOKIES_FUNCTIONAL.map((val) => {
                            return (
                                <tr key={val.name}>
                                    <td>{val.name}</td>
                                    <td>
                                        {val.description}
                                        {val.link && (
                                            <a target="_blank" href={val.link}>
                                                {val.link}
                                            </a>
                                        )}
                                    </td>
                                    <td>{val.storage_duration}</td>
                                    <td>{val.persistent_session}</td>
                                    <td>{val.category}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Box>

            <Typography variant="h2">INFORMATION COLLECTED FOR DEBUGGING</Typography>
            <Typography>
                To enhance your experience and improve our services, we collect additional environment information from
                the browser. This information is used to debug issues and ensure our software functions correctly across
                different browsers and extensions. The information collected includes:
            </Typography>
            <ul>
                <li>Browser User Agent</li>
                <li>Installed Browser Plugins</li>
                <li>Active Browser Extensions (e.g., Google Translate, Grammarly, DeepL, Stylus)</li>
                <li>Operating System</li>
                <li>Screen Resolution</li>
                <li>Viewport Size</li>
                <li>Language Preferences</li>
                <li>Time Zone</li>
                <li>Cookies Enabled Status</li>
                <li>Referrer URL</li>
                <li>Network Type</li>
                <li>Device Type (Desktop, Mobile, Tablet)</li>
            </ul>
            <Typography>
                This information is essential for diagnosing issues that may arise due to differences in browser
                environments and extensions. It helps us to ensure a seamless and bug-free experience for all users.
            </Typography>

            <Typography variant="h2">UPDATES TO PRIVACY POLICY</Typography>
            <Typography>
                We may modify this Privacy Policy from time to time by posting updates on this page. Please check back
                regularly to view any updates. Your continued use of our products and services after the effective date
                of the Privacy Policy means that you accept the revised Privacy Policy. If you do not agree to the
                revised Privacy Policy, please refrain from using our products or services and contact us to delete any
                Personal Information you may have given.
            </Typography>
            <Typography>This Privacy Policy was last updated on 26 March 2021.</Typography>

            <Typography variant="h2">CONTACT US</Typography>
            <Typography>
                Should you have any questions, concerns, or feedback regarding the Privacy Policy, please contact us at
                badge@futurenext.de.
            </Typography>
        </div>
    );
};

export default PrivacyContent;
