const BadgePreview: React.FC<{ img: string; disabled: boolean }> = ({ img, disabled }) => (
    <svg width="100%" height="100%" viewBox="0 0 437 437" fill="none" xmlns="http://www.w3.org/2000/svg">
        <image
            xlinkHref={img}
            x="0"
            y="15"
            width="100%"
            height="calc(100% - 30px)"
            style={{ filter: disabled ? "grayscale(1) contrast(0.2) brightness(1.9)" : undefined }}
        />
    </svg>
);
export default BadgePreview;
