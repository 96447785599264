import { useNavigate } from "react-router-dom";

import { useGetOrgaSlugs } from "../redux/hooks/validation";

const useInvalidateToken = () => {
    const navigate = useNavigate();

    const { orga_slug, certificate_slug } = useGetOrgaSlugs();

    const resetToken = () => {
        localStorage.setItem("token", "");
        navigate(`/${orga_slug}/${certificate_slug}/`);
    };
    return { resetToken };
};

export default useInvalidateToken;
