import { useTheme } from "@mui/material";

import Typography from "components/shared/Typography";

import { getStatusClass, getStatusIcon, root } from "./Helper.utils";

const Helper = ({ children, variant = "warning", classNames }: IHelperProps) => {
    const theme = useTheme();
    const classList = { ...root, ...getStatusClass(theme)[variant], ...classNames };

    return (
        <div>
            {children !== " " && (
                <div style={classList}>
                    {getStatusIcon(variant)}
                    <Typography>{children}</Typography>
                </div>
            )}
        </div>
    );
};

export default Helper;
