const UsLetterPortraitPreview: React.FC<{ img: string; disabled: boolean }> = ({ img, disabled }) => (
    <svg width="100%" height="100%" viewBox="0 0 440 559" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.1171 14.667H0.00119399L0.00117826 0L15.1171 14.667ZM0 14.6676H15.1159L15.1159 544.011L6.53267e-05 558.678L6.53267e-05 544.011H0L0 14.6676Z"
            fill="url(#paint0_linear_4074_1740)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.1159 14.6676L15.1159 0.000631422L0 0.000616163L15.1159 14.6676ZM15.1171 0L15.1171 14.667L424.162 14.667V14.6676L424.164 14.667V14.6663L439.278 0.000616163L424.164 0.000616163V0L15.1171 0Z"
            fill="url(#paint1_linear_4074_1740)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M424.162 544.011H439.278L439.278 558.678L424.162 544.011ZM439.278 544.01H424.162L424.162 14.6666L439.278 0.00012207V14.6666H439.278L439.278 544.01Z"
            fill="url(#paint2_linear_4074_1740)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M424.162 544.01V558.677L439.278 558.677L424.162 544.01ZM424.162 558.678V544.011L15.1158 544.011L0 558.677H15.1159L15.1158 558.678L424.162 558.678Z"
            fill="url(#paint3_linear_4074_1740)"
        />
        <image
            xlinkHref={img}
            x="0"
            y="15"
            width="100%"
            height="calc(100% - 30px)"
            style={{ filter: disabled ? "grayscale(1) contrast(0.2) brightness(1.9)" : undefined }}
        />
        <defs>
            <linearGradient
                id="paint0_linear_4074_1740"
                x1="0"
                y1="116.002"
                x2="15.1171"
                y2="116.002"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_4074_1740"
                x1="119.553"
                y1="0"
                x2="119.553"
                y2="14.6676"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_4074_1740"
                x1="439.278"
                y1="442.675"
                x2="424.162"
                y2="442.675"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_4074_1740"
                x1="319.725"
                y1="558.678"
                x2="319.725"
                y2="544.01"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00591071" stopColor="white" />
                <stop offset="0.0301009" stopColor="#E6E6E6" />
                <stop offset="0.529023" stopColor="white" />
                <stop offset="0.940256" stopColor="#EFEFEF" />
                <stop offset="1" stopColor="#F0F0F0" />
            </linearGradient>
        </defs>
    </svg>
);
export default UsLetterPortraitPreview;
