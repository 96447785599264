import { useRef } from "react";

import useTranslation from "i18n/hooks/useTranslation";
import { findRecipientImageInPolotnoProps } from "utils/misc";
import { theme } from "utils/Theme/theme";

import Box from "@mui/material/Box";

import Button from "components/shared/Button";

import badgeActionService from "../../../../redux/actions/badge";
import { useGetCurrentSelectedCertificate } from "../../../../redux/hooks/badge";
import eventSelectorService from "../../../../redux/selector/events";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import isOnBadgeRoute from "../../../../utils/isOnBadgeRoute";

type Props = {
    disableUpload: boolean;
    onUploadImage: () => void;
};

function UploadProfilePicture({ disableUpload, onUploadImage }: Props) {
    const t = useTranslation();
    const dispatch = useAppDispatch();
    const selectedCertificate = useGetCurrentSelectedCertificate();
    const isSocialMediaEnabled = useAppSelector(eventSelectorService.eventSocialMediaModeEnabled);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileInputChange = (e: any) => {
        onUploadImage();
        dispatch(
            badgeActionService.attachUploadedImageToBadge({
                certificate_id: selectedCertificate.certificate_id,
                image_blob_url: URL.createObjectURL(e.target.files[0]),
            })
        );
    };

    const manualImageUpload = () => {
        fileInputRef.current?.click();
    };

    const disableButton = () => {
        // Don't show button if social media mode is disabled or if the user is not on the badge page
        if (!isSocialMediaEnabled || !isOnBadgeRoute()) return true;

        // Don't show button if profile picture is disabled
        let profilePicDisabled = false;

        switch (selectedCertificate.badge_props_type) {
            case "badge":
                profilePicDisabled = !!(
                    selectedCertificate.badge_props as VbDesigner.IBadgeProps
                ).userInformationObjects.find((obj) => obj.id === "user-profile-pic")?.disabled;
                break;
            case "polotno":
                profilePicDisabled = !findRecipientImageInPolotnoProps(
                    selectedCertificate.badge_props as PolotnoDesigner.PolotnoBadgePropsData
                )?.visible;
                break;
            default:
                break;
        }
        if (profilePicDisabled) return true;

        return false;
    };

    if (disableButton()) return null;
    return (
        <Box display="flex" flexDirection="column" sx={{ mt: 3, alignItems: "center" }}>
            <Button
                variant="outlined"
                sx={{ color: theme.palette.grey[600], fontWeight: 500, mb: 1 }}
                onClick={manualImageUpload}
                disabled={disableUpload}
            >
                {t("upload_profile_picture.upload_button")}
            </Button>
            <input
                ref={fileInputRef}
                id="manualImageUpload"
                name="manualImageUpload"
                type="file"
                onChange={handleFileInputChange}
                accept="image/x-png,image/png,image/gif,image/jpeg"
                hidden
            />
        </Box>
    );
}

export default UploadProfilePicture;
