import { certificatePropsApi } from "./query/certificateProps";
import certificatesReducer from "./certificates";
import controlReducer from "./control";
import eventReducer from "./events";
import notificationReducer from "./notification";
import redirectionReducer from "./redirection";

export const rootReducer = {
    [certificatePropsApi.reducerPath]: certificatePropsApi.reducer,
    event: eventReducer,
    control: controlReducer,
    certificates: certificatesReducer,
    notification: notificationReducer,
    redirection: redirectionReducer,
};
