import React from "react";

import { CircularProgress } from "@mui/material";
import MuiButton from "@mui/material/Button";

const Button: React.FC<IButtonProps> = ({ children, disabled, loading, customStyles, ...rest }) => {
    return (
        <MuiButton disabled={disabled || loading} sx={customStyles} {...rest}>
            {loading ? <CircularProgress color="inherit" size="1em" /> : children}
        </MuiButton>
    );
};

export default Button;
