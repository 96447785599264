import { useDispatch } from "react-redux";

import { ESocialMediaPlatforms } from "../../types/enums";
import sideActionsService from "../actions/sideActions";
import type { TSocialMediaStateAction } from "../actions/types/sideActions.types";
import controlSelectorService from "../selector/control";
import type { TShareSocialMediaState } from "../slices/types/control.types";
import { useAppSelector } from "../store";

/**
 * Hook to set the share state to LinkedIn
 */
const useShareToLinkedIn = () => {
    const dispatch = useDispatch();
    return (type: keyof TShareSocialMediaState) => {
        const payload: TSocialMediaStateAction = {
            platform: ESocialMediaPlatforms.LINKEDIN,
            type,
        };
        dispatch(sideActionsService.shareToSocialMedia(payload));
    };
};

/**
 * Hook to set the share state to Facebook
 */
const useShareToFacebook = () => {
    const dispatch = useDispatch();
    return (type: keyof TShareSocialMediaState) => {
        const payload: TSocialMediaStateAction = {
            platform: ESocialMediaPlatforms.FACEBOOK,
            type,
        };
        dispatch(sideActionsService.shareToSocialMedia(payload));
    };
};

/**
 * Hook to set the share state to Twitter
 */
const useShareToTwitter = () => {
    const dispatch = useDispatch();
    return (type: keyof TShareSocialMediaState) => {
        const payload: TSocialMediaStateAction = {
            platform: ESocialMediaPlatforms.TWITTER,
            type,
        };
        dispatch(sideActionsService.shareToSocialMedia(payload));
    };
};

/**
 * Combine hook for social media share states
 *
 * @example
 * setPending(enum ESocialMediaPlatforms) // Set pending state of selected platform
 * setSuccessfull(enum ESocialMediaPlatforms) // Set successfull state of selected platform
 * setError(enum ESocialMediaPlatforms) // Set error state of selected platform
 * getShareLoadingState(enum ESocialMediaPlatforms)["twitter" | "facebook" | "linkedin"] // Get loading state of selected platform
 * @returns Callable functions to set and get loading states
 */
export const useShareToSocialMedia = () => {
    const linkedin = useShareToLinkedIn();
    const facebook = useShareToFacebook();
    const twitter = useShareToTwitter();

    const loadingState = useAppSelector(controlSelectorService.selectSocialMediaShareState);

    const setPending = (platform: ESocialMediaPlatforms) => {
        switch (platform) {
            case ESocialMediaPlatforms.LINKEDIN:
                linkedin("pending");
                break;
            case ESocialMediaPlatforms.FACEBOOK:
                facebook("pending");
                break;
            case ESocialMediaPlatforms.TWITTER:
                twitter("pending");
                break;
            default:
                break;
        }
    };
    const setSuccessfull = (platform: ESocialMediaPlatforms) => {
        switch (platform) {
            case ESocialMediaPlatforms.LINKEDIN:
                linkedin("successfull");
                break;
            case ESocialMediaPlatforms.FACEBOOK:
                facebook("successfull");
                break;
            case ESocialMediaPlatforms.TWITTER:
                twitter("successfull");
                break;
            default:
                break;
        }
    };

    const setError = (platform: ESocialMediaPlatforms) => {
        switch (platform) {
            case ESocialMediaPlatforms.LINKEDIN:
                linkedin("error");
                break;
            case ESocialMediaPlatforms.FACEBOOK:
                facebook("error");
                break;
            case ESocialMediaPlatforms.TWITTER:
                twitter("error");
                break;
            default:
                break;
        }
    };

    const getShareLoadingState = (platform: ESocialMediaPlatforms) => loadingState[platform];

    return {
        setPending,
        setSuccessfull,
        setError,
        getShareLoadingState,
    };
};
