import type { FC } from "react";
import styled from "@emotion/styled";

import useTranslation from "../../../i18n/hooks/useTranslation";
import eventSelectorService from "../../../redux/selector/events";
import { useAppSelector } from "../../../redux/store";
import { EVariantTranslation } from "../../../types/enums";
import { getTranslatedCertificateOrBadge } from "../../../utils/misc";

const OverviewHeaderTitel = styled.p`
    color: #000;

    /* Heading 1 - 32px */
    font-family: DM Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const CertificateOverviewHeader: FC = () => {
    const t = useTranslation();
    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);

    return (
        <div>
            <OverviewHeaderTitel>
                {t("certificate_overview.header.header_text", {
                    certificateType: getTranslatedCertificateOrBadge(
                        eventDetails?.event_type,
                        EVariantTranslation.LOWERCASE_PLURAL
                    ),
                } as any)}
            </OverviewHeaderTitel>
        </div>
    );
};

export default CertificateOverviewHeader;
