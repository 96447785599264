import { EAuthType } from "../../../types/enums";

export const CertificateValidatorApiRoutes = {
    getEvent: (orga_slug: string | undefined, event_slug: string | undefined) =>
        `/api/public/event?orga_slug=${orga_slug}&event_slug=${event_slug}`,
    validateToken: (orga_slug: string, certificate_slug: string, token: string) =>
        `/api/public/token?orga_slug=${orga_slug}&event_slug=${certificate_slug}&token=${token}`,
    postLogAuth: (orga_slug: string, certificate_slug: string, token: string, authType: EAuthType) =>
        `/api/public/participant?orga_slug=${orga_slug}&event_slug=${certificate_slug}&token=${token}&stats_auth_type=${authType}`,
    sendconfirmationCode: "/otp/code/",
};
