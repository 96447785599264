import NotFound from "../NotFound/NotFoundContainer";

const LandingPage = () => {
    // Check if the url has any query params and if it does, return null
    // We need this in order to differentiate between error page and redirects from the
    // OAuth process. These redirects point to the same page but with query params.
    const searchParams = new URLSearchParams(window.location.search);

    return <NotFound checkForParams={searchParams.size > 0} enableNavBar />;
};

export default LandingPage;
