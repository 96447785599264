import { FC, PropsWithChildren } from "react";

import { theme } from "utils/Theme/theme";

import { Box, Container, Grid, Toolbar } from "@mui/material";

import Footer from "components/Footer";
import LanguageSelector from "components/LanguageSelector";
import { TNotFoundAdditionalOptions } from "pages/NotFound/notFound.types";

import * as navBarStyles from "../../../../../components/NavBar/NavBar.styles";
import * as styles from "../../../../../layouts/AppLayout/AppLayout.styles";
import HandleBrandLogo from "../HandleBrandLogo/HandleBrandLogo";

/**
 *
 * @param enableNavBar Property to enable or disable the navigation bar based on the parent components
 * @param branding Object with event-logo
 * @returns
 */
const HandleNavigation: FC<
    PropsWithChildren<{ enableNavBar: boolean; branding: Partial<Record<TNotFoundAdditionalOptions, string>> | null }>
> = ({ enableNavBar, branding, children }) => {
    if (enableNavBar)
        return (
            <Box sx={styles.root}>
                <Box sx={navBarStyles.appBar}>
                    <Toolbar>
                        <Box sx={navBarStyles.ToolbarWrapper}>
                            {branding !== null ? <HandleBrandLogo branding={branding} /> : <div />}
                            <LanguageSelector />
                        </Box>
                    </Toolbar>
                </Box>
                <Container sx={styles.container} fixed>
                    <Grid container sx={styles.wrapper}>
                        {children}
                    </Grid>
                </Container>
                <div
                    style={{
                        margin: theme.spacing(0, 2, 0, 0),
                    }}
                >
                    <Footer withLogo={false} />
                </div>
            </Box>
        );
    return <>{children}</>;
};

export default HandleNavigation;
