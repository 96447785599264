import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { selectStore } from "../store";

const selectRedirectionStore = createDraftSafeSelector(selectStore, (store) => store.redirection);

const selectRedirection = createDraftSafeSelector(selectRedirectionStore, (redirect) => redirect.redirect);

const redirectionSelectorService = {
    selectRedirection,
    selectRedirectionStore,
};

export default redirectionSelectorService;
