/**
 * Retrieve available languages list
 * @returns
 */
function useAvailableLng() {
    const languagesList = [
        { value: "en", label: "English" },
        { value: "fr", label: "Français" },
        { value: "de", label: "Deutsch" },
        { value: "pt-BR", label: "Português (Brasil)" },
        { value: "pt", label: "Português (Portugal)" },
        { value: "nl", label: "Nederlands" },
        { value: "es", label: "Español" },
        { value: "ar", label: " العربية" },
    ];

    return languagesList;
}

export default useAvailableLng;
