import { useEffect } from "react";
import CertificateDownloadContainer from "features/certificateDownload/components/CertificateDownloadContainer";
import PolotnoWorkspace from "features/certificateDownload/components/PolotnoWorkspace/PolotnoWorkspace";

import ValidationLayout from "layouts/ValidationLayout";

import useLoadingThunk from "../../redux/hooks/loading";
import { useGetAutoVerificationParam, usePostValidation } from "../../redux/hooks/validation";
import certificatesSelectorService from "../../redux/selector/certificates";
import controlSelectorService from "../../redux/selector/control";
import { useAppSelector } from "../../redux/store";

import CertificateDownloadOverview from "./CertificateDownloadOverview";

const CertificateDownload = () => {
    const { isFulfilled } = useLoadingThunk();
    const userFlow = useAppSelector(controlSelectorService.selectUserFlow);
    const isPolotnoProps = useAppSelector(certificatesSelectorService.isPolotnoProps);
    const badgeIsParsing = useAppSelector(controlSelectorService.selectBadgeIsParsing);
    const isRichTextEnabled = useAppSelector(certificatesSelectorService.selectRichTextModeEnabled);
    const postvalidation = usePostValidation();
    const autoVerification = useGetAutoVerificationParam();

    useEffect(() => {
        postvalidation.setValidate(!isFulfilled("token") || autoVerification);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ValidationLayout>
            {isPolotnoProps && badgeIsParsing && <PolotnoWorkspace isRichTextEnabled={isRichTextEnabled} />}
            {userFlow.showCertificateOverview ? <CertificateDownloadOverview /> : <CertificateDownloadContainer />}
        </ValidationLayout>
    );
};

export default CertificateDownload;
