// import React from "react";

import { type FC, useMemo } from "react";

import useTranslation from "i18n/hooks/useTranslation";
import { socialMediaButtons } from "utils/commonStyles";
import { theme } from "utils/Theme/theme";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box, CircularProgress } from "@mui/material";

import Button from "components/shared/Button";

import * as styles from "./AddLinkedInButton.styles";

const AddLinkedInButton: FC<IAddLinkedInButtonProps> = ({ showLabel = true, label, loading, ...props }) => {
    const t = useTranslation();
    const buttonContainerStyle = useMemo(() => socialMediaButtons(showLabel), [showLabel]);
    return (
        <Box sx={buttonContainerStyle}>
            <Button
                variant="contained"
                startIcon={
                    loading ? (
                        <CircularProgress size={20} color="inherit" />
                    ) : (
                        <LinkedInIcon sx={{ height: 24, width: 24 }} />
                    )
                }
                sx={{
                    ...styles.linkedInButton,
                    "& .MuiButton-startIcon": {
                        marginRight: showLabel ? theme.spacing(1) : 0,
                    },
                    minWidth: showLabel ? 64 : "fit-content",
                    height: "100%",
                }}
                {...props}
            >
                {showLabel && (label || t("certificate.authForm.form.button_linkedin.text"))}
            </Button>
        </Box>
    );
};

export default AddLinkedInButton;
